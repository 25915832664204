
/*   -=========== Contact ===========-   */

@media $small-handheld {
  .contact-page {
    padding-top: 20px;
  }
}

.contact--caption {
  text-transform: uppercase;
  margin-bottom: 25px;
}

.contact--content {
  display: flex;
  justify-content: space-between;
  line-height: 1.5;
  margin-top: 45px;
  margin-bottom: 45px;

  @media $handheld {
    font-size: 1.5rem;
  }

  @media $small-handheld {
    flex-wrap: wrap;
    margin-bottom: 30px;
  }

  @media $mobile {
    flex-direction: column;
    margin-bottom: 15px;
  }
}

.contact--group {
  @media $not-small-handheld {
    & + & {
      margin-left: 20px;
    }
  }

  @media $portrait-tablet {
    flex-basis: 50%;
    margin-bottom: 50px;
  }

  @media $mobile {
    margin-bottom: 30px;
  }
}

@media $portrait-tablet {
  .contact--group_address {
    order: 1;
  }

  .contact--group_phones {
    order: 3
  }

  .contact--group_feedback {
    order: 4;
  }

  .contact--group_requisites {
    order: 2;
  }
}

.contact--subgroup + .contact--subgroup {
  margin-top: 30px;
  padding-top: 25px;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 31px; height: 2px;
    background: $black;
    position: absolute;
    left: 0; top: 0;
  }
}

.contact--field {
  & + & {
    margin-top: 18px;
  }
}

.contact--label {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.contact--label_commoncase {
  text-transform: none;
}

.contact--value {
  & + & {
    margin-top: 15px;
  }

  a[href^="mailto:"] {
    text-decoration: none;
  }

  .small-font {
    font-size: 1.3rem;
  }
}

.contact--value_formatted {
  white-space: pre-wrap;
}

.contact--feedback-link {
  display: inline-block;
  border-bottom: 1px solid rgba($black, .5);
  text-decoration: none;
  line-height: 1.1;
  transition: border-bottom-color .25s ease;

  &:hover {
    border-bottom-color: $black;
  }
}

.contact--map {
  height: 420px;
  filter: grayscale(1);

  .map {
    height: 100%;
  }
}


.contact--dealers {
  margin-top: 70px;
  margin-bottom: 90px;

  @media $small-handheld {
    margin-bottom: 30px;
  }

  @media $mobile {
    margin-bottom: 15px;
  }
}

.contact--dealers-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  padding-top: 10px;

  @media $handheld {
    grid-template-columns: repeat(3, 1fr);
  }

  @media $small-handheld {
    grid-template-columns: repeat(2, 1fr);
  }

  @media $small-mobile {
    grid-template-columns: 100%;
  }

  .item {
    font-size: 1.6rem;
  }

  .item--logo {
    margin-bottom: 15px;
  }

  .item--name {
    text-transform: uppercase;
    font-size: 1.7rem;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .item--address {
    margin-bottom: 7px;
  }

  .item--info + .item--info {
    margin-top: 7px;
  }

  .item--info-label {
    font-weight: bold;
    margin-bottom: 3px;
  }
}
