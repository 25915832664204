
/*   -=========== Popups ===========-   */

// Base

.popup {
  display: none;
  box-sizing: border-box;
  position: absolute;
  background: $body-bg;
  z-index: 333;
  padding: 30px 40px 35px;

  @media $small-mobile {
    width: 100%;
    padding-left: 35px;
    padding-right: 35px;
  }
}

.popup--close {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0; right: 0;
  cursor: pointer;

  &:before, &:after {
    content: '';
    display: block;
    width: 2px; height: 20px;
    position: absolute;
    left: 50%; top: 50%;
    background: $black;
    margin-top: -9px;
  }

  &:before {
    transform: rotate(-45deg);
  }

  &:after {
    transform: rotate(45deg);
  }
}

.popup--caption {
  @extend .h2;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.callback-popup,
.feedback-popup {
  max-width: 320px;
}

.order-size-popup {
  width: 620px;
  max-width: 100%;
}


// Tooltip

.css-tooltip {
  display: inline-block;
  line-height: 1;
  border-bottom: 1px dashed rgba($black, .5);
  position: relative;

  &:before {
    content: attr(data-tooltip);
    display: block;
    width: 250px;
    max-height: 0;
    opacity: 0;
    background: rgba($black, .7);
    color: $white;
    transition: all .25s ease;
    padding: 0 10px;
    overflow: hidden;
    position: absolute;
    left: 50%; bottom: 100%;
    transform: translateX(-50%);
    font-size: 1.2rem;
    line-height: 1.2;
  }

  &:hover:before {
    max-height: 800px;
    opacity: 1;
    padding: 10px 15px;
  }
}

// #47697: фикс смещения курсора поля ввода на iOS 11.0 - 11.2.6 (текущая) 19.03.2018
// возможно потом Apple нативно поправит баг
.is-ios.uniloader-overlay-html body{
  position: fixed;
}