.carved-page {
  position: relative;

  .carved-nav {
    position: fixed;
    top: 180px;
    background-color: rgba($black, 0.7);
    width: 100%;
    z-index: 1;
    color: $white;
    transition: all 150ms linear;

    @media $small-handheld {
      top: 55px;
    }

    @media $small-mobile {
      display: none;
    }

    .mini-header & {
      top: 113px;

      @media $small-handheld {
        top: inherit;
      }
    }
  }

  .carved-nav__list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    li {
      list-style-type: none;
    }

    li a {
      color: $white;
      font-weight: 800;
      font-size: 13px;
      line-height: 5.5rem;
      text-decoration: none;
      text-transform: uppercase;
      display: inline-block;
      border-bottom: 2px solid transparent;
      transition: all 100ms linear;

      &:hover,
      &.active {
        border-color: $white;
      }
    }
  }

  .carved-page__title {
    font-weight: 800;
    font-size: 32px;
    margin-bottom: 55px;
    text-align: center;
    text-transform: uppercase;

    @media $small-mobile {
      font-size: 18px;
      margin-bottom: 40px;
    }
  }

  .carved-section {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    background: url('/i/carved.jpg') no-repeat 50%/cover;
    height: height('i/carved.jpg');
    color: $white;
    position: relative;
    text-transform: uppercase;

    @media $small-handheld {
      height: 500px;
    }
  }

  .carved__title {
    font-weight: 800;
    font-size: 64px;
    letter-spacing: 2px;

    @media $small-handheld {
      font-size: 55px;
    }

    @media $small-mobile {
      font-size: 32px;
    }
  }

  .carved__subtitle {
    font-weight: 400;
    font-size: 22px;
    margin-top: 5px;
    margin-bottom: 70px;
    letter-spacing: 2px;

    @media $small-handheld {
      font-size: 16px;
    }

    @media $small-mobile {
      font-size: 15px;
      margin-top: 20px;
      margin-bottom: 50px;
    }
  }

  .carved__link {
    box-sizing: border-box;
    font-weight: 700;
    font-size: 11px;
    text-decoration: none;
    display: inline-block;
    padding: 20px 40px;
    transition: all 100ms linear;
  }

  .carved--offer-section {
    margin: 100px 0;
    background: url('/i/partition-carved.jpg') no-repeat 50%;

    @media $small-handheld {
      background: none;
      padding-left: 0;
    }

    @media $small-mobile {
      margin: 0;

      .offer-section--list .item {
        padding-left: 30px;
      }
    }

    .offer-section--list .item::before {
      background: $black;
      border-radius: 100%;
      width: 32px; height: @width;

      @media $small-mobile {
        width: 17px; height: @width;
      }
    }

    .offer-section--caption {
      @media $small-mobile {
        text-align: center;

        &::after {
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  .carved-model {
    background: url('/i/carved-banner.jpg') no-repeat 50%/contain;
    height: height('i/carved-banner.jpg');

    @media $handheld {
      background-size: cover;
    }
  }

  .carved-model__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $white;
    height: 100%;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .carved-model__title {
    font-weight: 800;
    font-size: 40px;
    display: inline-block;
    margin-bottom: 22px;
    text-align: center;

    @media $small-mobile {
      font-size: 24px;
    }
  }

  .carved-model__subtitle {
    font-weight: 400;
    font-size: 16px;

    @media $small-mobile {
      font-size: 14px;
      text-align: center;
    }
  }

  .carved-catalogue {
    padding: 60px 0;
  }

  .carved-catalogue__inner {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 25px;
    grid-row-gap: 35px;
    position: relative;
    padding-bottom: 110px;

    &.all-shown {
      padding-bottom: 0;
    }

    @media $small-mobile {
      grid-template-columns: 1fr;

    }
  }

  .carved-catalogue__item {
    text-decoration: none;
    display: block;

    &:not(.is-shown) {
      display: none;
    }

    > span {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 20px;
      display: inline-block
      margin-top: 10px;
    }

    > img {
      height: 280px;
      width: 100%;
      object-fit: cover;

      @media $handheld {
        height: 220px;
      }

      @media $small-mobile {
        height: auto;
      }
    }
  }

  .carved-catalogue__show-more {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    @media $small-mobile {
      width: 100%;
    }
  }

  .carved-catalogue__show-more-link {
    position: relative;
    font-weight: 700;
    font-size: 20px;
    color: $black;
    text-decoration: none;
    display: flex;
    align-items: center;

    @media $small-mobile {
      justify-content: center;
    }

    > i {
      margin-right: 5px;
      width: width('i/add-icon.svg');
      height: height('i/add-icon.svg');
    }
  }

  .carved-works {
    width: 100%;
    margin: 50px 0 100px;
  }

  .carved-works__slider-item {

    > img {
      height: 600px;
      width: 100%;
      object-fit: cover;

      @media $handheld {
        height: 400px;
      }
    }

    > .wrapper {
      margin-top: 25px;
    }
  }

  .carved-works__slider {
    position: relative;

    &:hover .slick-arrow {
      opacity: .7;
    }

    .slick-arrow {
      transition: all 200ms ease;
      position: absolute;
      top: 50%;
      translate: 0 -70%;
      z-index: 1;
      opacity: 0;
      font-size: 0;
      background-color: rgba($black, 0.7);
      display: block;
      width: 40px; height: @width;

      &:hover {
        opacity: 1;
      }

      &::after {
        content: '';
        display: block;
        width: 12px; height: @width;
        rotate: 45deg;
        background: transparent;
        position: absolute;
        left: 50%; top: 50%;
      }
    }

    .slick-prev {
      left: 300px;

      @media $notebook-and-less {
        left: 60px;
      }

      @media $handheld {
        left: 30px;
      }

      &::after {
        translate: -25% -50%;
        border-left: 2px solid $white;
        border-bottom: 2px solid $white;
      }
    }

    .slick-next {
      right: 300px;

      @media $notebook-and-less {
        right: 60px;
      }

      @media $handheld {
        right: 30px;
      }

      &::after {
        translate: -70% -50%
        border-right: 2px solid $white;
        border-top: 2px solid $white;
      }
    }

    .slick-dots {
      position: absolute;
      left: 50%; bottom: -35px;
      translate: -50% 0;
      display: flex;
      flex-direction: row;
      list-style-type: none;
      font-size: 0;

      li {
        width: 10px; height: @width;
        background-color: $light-gray;
        transition: all 100ms ease;
        border-radius: 100%;
        cursor: pointer;

        &.slick-active {
          background-color: $black;
        }

        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }

  .carved-quiz {
    color: $white;
    height: 660px;
    background: url('/i/carved_quiz.jpg') 50% no-repeat;
    background-size: contain;
    box-sizing: border-box;
    padding-top: 150px;
    margin-bottom: 50px;

    @media $handheld {
      padding-top: 0;
      height: 400px;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .carved-quiz__title {
    font-weight: 800;
    font-size: 36px;
    max-width: 750px;
    margin-bottom: 15px;
    text-transform: uppercase;

    @media $small-mobile {
      font-size: 24px;
    }
  }

  .carved-quiz__content {
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 70px;
    max-width: 750px;

    @media $small-mobile {
      font-size: 14px;
    }
  }

  .carved-quiz__popup-link {
    box-sizing: border-box;
    background: $orange;
    width: 300px;
    line-height: 60px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    @media $small-mobile {
      margin: 0 auto;
    }
  }
}

.carved-quiz-popup {
  width: 60vw;
  height: 80vh;

  @media $small-mobile {
    width: 90vw;
  }

  .partitions-quiz__frame {
    @media $handheld {
      height: 100%;
    }
  }
}