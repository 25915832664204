
/*   -=========== Filter ===========-   */

$filters-gray = #e8e8e8;

.filter-aside {
  padding: 75px 50px 70px 80px;
  background: $filters-gray;
  max-width: 358px;
}

.left-filter--caption {
  @extend .h3;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  margin-bottom: 15px;

  .selected-filters & {
    margin-bottom: 25px;
  }
}

.left-filter--toggler {
  width: 40px; height: 40px;
  position: absolute;
  right: -10px; top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  &:before, &:after {
    content: '';
    display: block;
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -50%);
    background: $black;
  }

  &:before {
    width: 14px; height: 2px;
  }

  &:after {
    width: 2px; height: 14px;
    opacity: 0;
    transition: opacity .25s ease;

    .is-collapsed & {
      opacity: 1;
    }
  }
}

.selected-filters {
  margin-bottom: 30px;

  .item {
    position: relative;
    padding-right: 30px;
  }

  .item + .item {
    margin-top: 10px;
  }

  .item--label {
    font-weight: bold;
  }

  .remove-btn {
    display: block;
    width: 20px; height: 20px;
    position: absolute;
    right: 0; top: 0;

    &:before, &:after {
      content: '';
      display: block;
      width: 2px; height: 16px;
      background: #a4a4a4;
      position: absolute;
      top: 50%; left: 50%;
      margin-top: -8px;
      margin-left: -1px;
      transition: all .25s ease;
    }

    &:before {
      transform: rotate(-45deg);
    }

    &:after {
      transform: rotate(45deg);
    }

    &:hover {
      &:before, &:after {
        background: $black;
      }
    }
  }
}

.selected-filters--body {
  margin-bottom: 30px;
  font-size: 1.5rem;
}

.selected-filters--reset {
  @extend .btn, .black-bordered-btn, .h49btn;
  width: 152px;
  font-size: 1.6rem;
  font-weight: bold;
  font-variant: small-caps;
  text-transform: lowercase;
}

.left-filter--group {
  border-top: 1px solid $white;
  padding-top: 10px;
}

.left-filter--body {
  overflow: hidden;
  max-height: 2000px;
  padding-bottom: 30px;
  transition: all .25s ease;
  font-size: 1.5rem;

  .item {
    position: relative;

    a {
      text-decoration: none;
      font-weight: bold;
    }
  }

  .item + .item {
    margin-top: 10px;
  }

  .is-collapsed & {
    max-height: 0;
    opacity: 0;
    padding-bottom: 0;
  }
}

.checkbox-filter {
  font-weight: 300;
  cursor: pointer;
  transition: color .25s ease;

  &:after {
    content: '';
    display: block;
    width: 20px; height: 20px;
    background: url(/i/check.png) no-repeat 50%;
    position: absolute;
    right: 0; top: 0;
    opacity: 0;
    transition: opacity .25s ease;
  }

  &.checked {
    font-weight: 700;

    &:after {
      opacity: 1;
    }
  }

  input {
    display: none;
  }
}

.colors-filter {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  margin-left: -3px;

  .color-box {
    display: block;
    margin-right: 10px;
    width: 28px; height: @width;
    position: relative;
    border-radius: 50%;

    &:before, &:after {
      content: '';
      display: block;
      border-radius: 50%;
      position: absolute;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
    }

    &:before {
      width: 30px; height: @width;
      background-color: $filters-gray;
      transition: all .25s ease;
    }

    &:after {
      width: 20px; height: @width;
      background-color: inherit;
    }

    &.checked:before {
      width: 26px; height: @width;
    }
  }
}
