
/*   -=========== Homepage ===========-   */

.rotator-wrapper {
  position: relative;
}

.rotator-wrapper_main {
  @media $portrait-tablet {
    height: 674px;
  }

  @media $mobile {
    height: 374px;
  }

  .main-rotator {
    height: 100%;
    overflow: hidden;

    @media $small-handheld {
      img {
        max-width: none;
        height: 100%;
        position: absolute;
        left: 50%; top: 0;
        transform: translateX(-50%);
      }
    }
  }

  .tos-uibg {
    display: none;
  }

  .tos-wrapper.tos-has-bullets .tos-pagination {
    width: auto; height: auto;
    left: auto; bottom: 5%; right: 5%;
    overflow: visible;
    opacity: 1;

    a {
      width: 12px; height: @width;
      margin: 0 7px;
      background: $black;
      opacity: .3;
      transition: opacity .25s ease;

      &.tos-selected {
        opacity: 1;
      }
    }

    &:before, &:after {
      display: none;
    }
  }

  /* &:after {
    content: '';
    display: block;
    width: 2px; height: 31px;
    background: $black;
    position: absolute;
    left: 50%; bottom: -16px;
    margin-left: -1px;
    z-index: 500;
  } */
}


// About section

.about-section {
  @media $not-mobile {
    padding-top: 95px;
    padding-bottom: 80px;

    .wrapper {
      background: url(/i/home-pic-1.jpg) no-repeat 0 50%;
      min-height: 440px;

      @media $tablet {
        background-size: 45% auto;
      }
    }
  }

  @media $mobile {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media $not-mobile {
  .about-section--content {
    padding-left: 50%;
    position: relative;
  }
}

.about-section--title {
  font-weight: bold;
  position: absolute;
  left: 0; bottom: 40px;
  letter-spacing: 1px;

  @media $mobile {
    display: none;
  }
}

.about-section--caption {
  @extend .caption;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;

  &:after {
    display: none;
  }
}

.about-section--subcaption {
  @extend .h1;
  text-transform: none;
  font-variant: normal;
  font-weight: $light;
  line-height: 1.2;
  letter-spacing: 2px;
  margin-bottom: 30px;
}

.about-section--text {
  letter-spacing: 1px;

  @media $not-mobile {
    font-size: 1.6rem;
    line-height: 1.75;
    text-align: justify;
  }

  @media $mobile {
    font-size: 1.4rem;
    line-height: 1.35;
  }
}


// Categories

.categories-section {
  display: flex;

  @media $mobile {
    flex-wrap: wrap;
  }

  .category {
    flex: 1;
    display: block;
    position: relative;
    background-position: 50%;
    background-size: cover;

    .image {
      width: 100%; height: 100%;
      background-position: 50%;
      background-size: cover;
    }

    &:before {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    &.category_half-height:before {
      padding-bottom: 50%;
    }

    &:after {
      content: '';
      display: block;
      width: 100%; height: 100%;
      background: rgba($black, .5);
      position: absolute;
      left: 0; top: 0;
      opacity: .3;
      transition: opacity .25s ease;
    }

    @media $mobile {
      flex-basis: 100%;
    }
  }

  .category + .category {
    margin-left: 10px;

    @media $mobile {
      margin-left: 0;
      margin-top: 10px;
    }
  }

  // из-за багов в Safari
  // вот такая реализация
  .category-after-stripe {
    position: absolute;
    display: block;
    content: '';
    height: 10px;
    background-color: #fff;
    z-index: 10;
    width: calc(50% - 5px);
    margin-top: calc(25% - 10px);

    @media only screen and (max-width: 700px){
      width: 100%;
      margin-top: calc(50% - 10px);
    }
  }

  .category--caption {
    width: 90%;
    font-size: 3.2rem;
    font-weight: bold;
    text-transform: lowercase;
    font-variant: small-caps;
    letter-spacing: 1px;
    color: $white;
    text-align: center;
    position: absolute;
    left: 5%; bottom: 15%;
    z-index: 1;
    transition: color .25s ease;

    @media $landscape-tablet {
      font-size: 2rem;
    }

    @media $portrait-tablet {
      font-size: 1.6rem;
    }

    @media $mobile {
      font-size: 1.3rem;
    }
  }

  .category--more-icon {
    display: block;
    width: 55px; height: 55px;
    background: url(/i/more-icon.png) no-repeat 50%;
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    opacity: 0;
    transition: opacity .25s ease;
  }

  .category:hover {
    .category--caption {
      color: $white;
    }

    .category--more-icon {
      opacity: 1;
    }

    &:after {
      opacity: 1;
    }
  }

  .category_big {
    .category--caption {
      bottom: 7.5%;
    }
  }
}

.categories-section_wide {
  .category:before {
    padding-bottom: 76%;
  }

  .category--caption {
    bottom: 8%;
  }
}

.categories-section--half-block {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-left: 10px;

  .category {
    flex-basis: calc(50% - 10px);
  }

  @media $mobile {
    display: block;
    flex-basis: 100%;
    padding-left: 0;
    padding-top: 10px;
  }
}


// Features

$feature-padding-left = 85px;

.shop-features-section {
  padding-top: 80px;
  padding-bottom: 55px;

  @media $mobile {
    padding-top: 45px;
    padding-bottom: 35px;
  }
}

.shop-features-section--caption {
  @extend .caption;
  text-transform: uppercase;

  &:after {
    display: none;
  }

  @media $not-mobile {
    margin-left: $feature-padding-left;
  }
}

.shop-features {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .feature {
    @media $not-mobile {
      flex-basis: calc(50% - 60px);
      margin-top: 30px;
      margin-bottom: 30px;
    }

    @media $mobile {
      flex-basis: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .feature--number {
    font-weight: bold;
    line-height: .8;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    &:after {
      content: '';
      display: block;
      height: 2px;
      background: $black;
      flex-basis: 'calc(100% - %s)' % $feature-padding-left;
    }

    @media $not-mobile {
      font-size: 6.4rem;
      margin-bottom: 30px;
    }

    @media $mobile {
      font-size: 3rem;
      margin-bottom: 20px;

      &:after {
        flex-basis: calc(100% - 44px);
      }
    }
  }

  .feature--caption {
    font-weight: bold;
    text-transform: uppercase;

    @media $not-mobile {
      padding-left: $feature-padding-left;
      font-size: 2.4rem;
      margin-bottom: 20px;
    }

    @media $mobile {
      font-size: 1.4rem;
      margin-bottom: 15px;
    }
  }

  .feature--text {
    letter-spacing: 1px;
    text-align: justify;
    color: $gray;

    @media $not-mobile {
      padding-left: $feature-padding-left;
      line-height: 1.75;
    }

    @media $mobile {
      font-size: 1.4rem;
      line-height: 1.35;
    }
  }
}


// Showroom

.showroom-section {
  padding-top: 85px;

  .wrapper {
    padding-bottom: 85px;
  }
}

.showroom-section--caption {
  @extend .caption;
  text-transform: uppercase;
  margin-bottom: 35px;
}

.showroom-section--address {
  font-size: 2.2rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1em;

  @media $mobile {
    font-size: 1.6rem;
  }
}

.showroom-section--subcaption {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1em;
}

.showroom-section--text {
  font-size: 1.3rem;
  line-height: 1.5;

  .big-text {
    font-size: 1.5rem;
  }
}


// Clients

.clients-section {
  padding-top: 110px;
  padding-bottom: 130px;

  @media $mobile {
    padding-top: 35px;
    padding-bottom: 35px;
  }
}

.clients-section--caption {
  @extend .h1;
  text-align: center;
  text-transform: uppercase;

  @media $not-mobile {
    margin-bottom: 60px;
  }

  @media $mobile {
    margin-bottom: 20px;
  }
}

.clients-slider-wrapper {
  position: relative;

  @media only screen and (max-width: $wrapper-width + 100px) {
    padding: 0 60px;
  }

  @media $mobile {
    padding: 0;
    text-align: center;
  }
}

.clients-slider {
  .tos-slider {
    display: flex;
    //flex-direction: column;
    //flex-wrap: wrap;
    height: 175px;
  }

  .tos-slide {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    //width: 33.33% !important;
    //height: 33.33% !important;
    width: 25% !important;
    height: 100% !important;

    /* &:nth-child(even) {
      border-top: 1px solid #e4e4e4;
    }

    &:nth-child(2) ~ .tos-slide {
      border-left: 1px solid #e4e4e4;
    } */
  }

  .item {
    display: block;
    text-decoration: none;
    filter: grayscale(1);
    opacity: .5;
    transition: all .25s ease;

    &:hover {
      filter: grayscale(0);
      opacity: 1;
    }
  }

  img {
    max-width: 90%;
  }

  @media $mobile {
    .item + .item {
      margin-top: 20px;
    }

    img {
      max-width: 132px;
      max-height: 40px;
    }
  }
}

.clients-slider--control {
  display: none;
  width: 55px; height: 65px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: opacity .25s ease;

  &:before, &:after {
    content: '';
    display: block;
    width: 2px; height: 22px;
    background: $black;
    position: absolute;
  }

  &:before {
    bottom: 50%;
  }

  &:after {
    top: 50%;
  }

  &:focus {
    margin-top: -1px;
  }

  &:hover {
    opacity: .6;
  }
}

.clients-slider--prev {
  right: 100%;
  margin-right: 15px;

  &:before, &:after {
    left: 50%;
    margin-left: -10px;
  }

  &:before {
    transform: rotate(45deg);
    transform-origin: bottom left;
  }

  &:after {
    transform: rotate(-45deg);
    transform-origin: top left;
  }

  @media only screen and (max-width: $wrapper-width + 100px) {
    right: auto;
    left: 0;
  }
}

.clients-slider--next {
  left: 100%;
  margin-left: 15px;

  &:before, &:after {
    right: 50%;
    margin-right: -10px;
  }

  &:before {
    transform: rotate(-45deg);
    transform-origin: bottom right;
  }

  &:after {
    transform: rotate(45deg);
    transform-origin: top right;
  }

  @media only screen and (max-width: $wrapper-width + 100px) {
    left: auto;
    right: 0;
  }
}
