
/*   -=========== Partitions ===========-   */

@media $not-mobile {
  .partitions-body {
    padding-bottom: 97px;
  }
}

.partitions-page {
  .caption-section {
    // background: url(/i/partitions-1.jpg) no-repeat 50% / auto 100%;
    background: url(/i/partitions-1-2.jpg) no-repeat 50% / cover;
    color: $white;
    position: relative;

    @media $small-mobile {
      background-image: url(/i/partitions-1-2-mob.jpg);
      // background-image: url(/i/partitions-1-mob.jpg);
      background-size: cover;
    }

    .wrapper {
      height: 814px;
      // height: 674px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media $landscape-tablet {
        height: 640px;
        // height: 540px;
      }

      @media $small-handheld {
        // height: 400px;
        position: relative;
      }

      @media $small-mobile {
        height: 440px;
      }

      @media $small-mobile {
        // height: auto;

        // &:before {
        //   content: '';
        //   display: block;
        //   padding-bottom: 168%;
        // }

        // * {
        //   display: none;
        // }
      }
    }

    .caption-section--caption {
      text-align: center;
      font-size: 8rem;
      font-weight: bold;
      font-variant: small-caps;
      text-transform: lowercase;
      line-height: 1;
      padding-bottom: 20px;
      margin-bottom: 15px;
      position: relative;

      @media $handheld {
        font-size: 6rem;
      }

      @media $small-mobile {
        font-size: 3.4rem;
      }

      &:after {
        content: '';
        display: block;
        width: 31px; height: 2px;
        background: $white;
        position: absolute;
        left: 50%; top: 100%;
        transform: translateX(-50%);
      }
    }

    .caption-section--text {
      font-size: 2.7rem;
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      line-height: 1.5;

      @media $small-handheld {
        font-size: 2rem;
      }

      @media $small-mobile {
        font-size: 1.2rem;
      }
    }

    .caption-section--incut {
      background: rgba(#000, .25);
      max-width: 1060px;
      margin-top: 40px;
      color: $white;
      padding: 35px 50px;
      box-sizing: border-box;
      font-size: 4.7rem;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      position: relative;
      left: 50%;
      transform: translateX(-50%);

      @media $handheld {
        font-size: 3.6rem;
      }

      @media $small-mobile {
        margin-left: -25px;
        margin-right: -25px;
        left: auto;
        transform: none;
        padding: 5px 25px;
        font-size: 2rem;
        line-height: 1.5;
      }
    }

    .caption-section--date {
      font-size: 3rem;
      font-weight: normal;
      text-transform: none;
      margin-top: .75em;

      @media $handheld {
        font-size: 2rem;
      }

      @media $small-mobile {
        font-size: 1.4rem;
      }
    }

    .caption-section--buttons {
      display: flex;
      justify-content: center;
      margin-top: 3em;

      @media $small-mobile {
        flex-direction: column;
        align-items: center;
        margin-top: 1em;
        padding-bottom: 2em;
      }
    }

    .caption-section--test-btn {
      @extend .btn, .orange-btn;
      // @extend .btn, .white-bordered-btn;
      background: $orange;
      width: 380px;
      line-height: 60px;
      text-transform: uppercase;
      font-variant: small-caps;
      font-weight: bold;
      color: $black;

      @media $small-mobile {
        width: 280px;
        line-height: 50px;
        height: 54px;
        display: block !important;
        text-transform: lowercase;
        font-size: 1.8rem;
      }
    }

    .caption-section--calc-btn {
      @extend .btn, .white-bordered-btn;
      width: 380px;
      line-height: 60px;
      text-transform: uppercase;
      font-variant: small-caps;
      font-weight: bold;
      color: $white;
      margin-left: 3em;

      @media $small-mobile {
        width: 280px;
        line-height: 50px;
        height: 54px;
        display: block !important;
        text-transform: lowercase;
        font-size: 1.8rem;
        margin-left: 0;
        margin-top: 1em;
      }
    }
  }

  @media $small-handheld {
    .caption-section--mob-anchor-area{
      position: absolute;
      width: 100%;
      height: 100px;
      margin-top: -100px;
    }
  }

  .offer-section {
    @media $not-small-mobile {
      background: url(/i/partitions-2.jpg) no-repeat 50%;

      @media $small-handheld {
        background-position: 41.3% 50%;
      }
    }

    .wrapper {
      padding-top: 90px;
      padding-bottom: 90px;

      @media $tablet {
        padding-top: 60px;
        padding-bottom: 60px;
      }

      @media $mobile {
        padding-top: 50px;
        padding-bottom: 50px;
      }
    }

    @media $not-small-mobile {
      .wrapper--inner {
        padding-left: 40%;
      }
    }

    .offer-section--caption {
      @extend .caption;
      text-transform: uppercase;
      margin-bottom: 60px;

      @media $mobile {
        margin-bottom: 30px;
      }
    }
  }

  .offer-section--list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .item {
      box-sizing: border-box;
      flex-basis: 48%;
      line-height: 1.75;
      font-weight: normal;
      padding-left: 70px;
      position: relative;

      @media $small-handheld {
        flex-basis: 100%;

        br {
          display: none;
        }
      }

      @media $small-mobile {
        font-size: 1.4rem;
      }

      &:before {
        content: '';
        display: block;
        width: 36px; height: @width;
        background: url(/i/mark.png) no-repeat 50%;
        position: absolute;
        left: 0; top: 50%;
        transform: translateY(-50%);
      }

      @media $not-small-handheld {
        &:nth-child(2) ~ .item {
          margin-top: 60px;
        }
      }

      @media $small-handheld {
        & + .item {
          margin-top: 20px;
        }
      }
    }
  }

  .offer-slider {
    padding: 55px 0 100px;
  }

  .offer-slider--caption {
    font-weight: 800;
    font-size: 32px;
    display: block;
    text-align: center;
    position: relative;
    margin-bottom: 70px;
    text-transform: uppercase;

    @media $not-small-mobile {
      br {
        display: none;
      }
    }

    @media $small-mobile {
      font-weight: 800;
      font-size: 18px;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      width: 30px; height: 2px;
      background-color: $black;
    }
  }

  .offer-slider--section {
    & + .offer-slider--section {
      margin-top: 70px;
    }
  }

  .offer-slider-caption {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 1.8rem;
  }

  .offer-slider--body {
    position: relative;

    .slick-track {
      margin-left: 0;
    }

    .slick-arrow {
      position: absolute;
      top: -30px;
      transform: translateY(-50%);
      width: 40px; height: 15px;
      background-color: transparent;
      font-size: 0;
      opacity: 0.4;
      transition: opacity 250ms ease;

      &:hover {
        opacity: 1;
      }
    }

    .slick-prev {
      right: 60px;
      background: url(/i/partitions-slider-arrow-prev.svg) 50% / contain no-repeat;
    }

    .slick-next {
      right: 0;
      background: url(/i/partitions-slider-arrow.svg) 50% / contain no-repeat;
    }

    .slick-list {
      margin-left: -15px;
      margin-right: -15px;
    }

    .slick-dots {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;

      @media $small-mobile {
        margin-top: 0;
      }

      > li {
        display: block;
        font-size: 0;
        width: 11px; height: @width;
        border-radius: 100%;
        background-color: #D9D9D9;
        transition: all 100ms ease;
        margin: 0 5px;

        &.slick-active {
          transform: scale(1.5);
          background-color: $black;
        }
      }
    }
  }

  .offer-slider--item {
    margin: 0 15px;
    text-decoration: none;

    > img {
      width: 305px;
      height: @width;
      object-fit: cover;

      @media $small-mobile {
        width: 100%;
        height: 325px;
      }
    }

    &-title {
      font-weight: 700;
      font-size: 20px;
      margin: 20px 0;
      text-transform: uppercase;
    }

    > span {
      font-weight: 400;
      font-size: 15px;
      margin-bottom: 25px;
      display: inline-block;
    }

    &-btn {
      display: block;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      text-decoration: none;
      height: 50px;
      width: 210px;
      font-weight: 700;
      font-size: 11px;
      color: $black;
      transition: all 100ms ease;

      &:hover {
        color: $white;
      }

      @media $mobile {
        width: 100%;
      }
    }
  }

  .offer-slider__redirect {
    text-align: center;
    margin-top: 60px;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;

    a {
      transition: all 250ms ease;
      display: inline-flex;
      padding: 10px;
      font-size: 1.4rem;
      text-decoration: none;
    }
  }

  .how-section {
    background: url(/i/partitions-3.jpg) no-repeat 50%;

    @media $small-mobile {
      /* background-repeat: repeat-y; */
      background-size: cover;
    }

    .wrapper {
      height: 869px;

      @media $tablet {
        height: 650px;
      }

      @media $small-mobile {
        height: auto;
      }
    }
  }

  .how-section--list {
    display: flex;
    flex-wrap: wrap;
    height: 100%;

    .item {
      box-sizing: border-box;
      color: $white;
      border-bottom: 1px solid #a1a1a0;
      position: relative;
      text-align: center;

      @media $small-mobile {
        flex-basis: 50%;

        &:nth-child(even) {
          border-left: 1px solid #a1a1a0;
        }

        &:before {
          content: '';
          display: block;
          padding-bottom: 130%;
        }
      }

      @media $not-small-mobile {
        flex-basis: 33.33%;
        height: 50%;
        border-right: 1px solid #a1a1a0;

        &:nth-child(3) ~ .item {
          border-bottom: none;
        }

        &:nth-child(3n + 3) {
          border-right: none;
        }
      }
    }

    .item--number {
      position: absolute;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
      margin-top: -30px;
      font-size: 26.3rem;
      font-weight: bold;
      color: rgba($black, .47);

      @media $small-mobile {
        height: 100%;
        width: 100%;
        margin-top: 0;
        font-size: 40vw;
      }

      &:before {
        content: '';
        display: block;
        width: 100%; height: 88%;
        position: absolute;
        left: 0; bottom: 0;

        @media $small-mobile {
          bottom: 7%;
          height: 100%;
          background-size: 40% !important;
        }
      }
    }

    .item--text {
      position: absolute;
      left: 50%; top: calc(65% + 40px);
      transform: translateX(-50%);
      font-weight: normal;

      @media $small-mobile {
        width: 100%;
      }

      @media only screen and (max-width: 400px) {
        font-size: 1.2rem;
        top: calc(55% + 40px);
      }
    }

    for $item in 1..6 {
      .item_{$item} .item--number:before {
        background: url('/i/partitions-how-' + $item + '.png') no-repeat 50%;
      }
    }
  }

  .your-actions-section {
    padding-top: 100px;
    padding-bottom: 110px;

    @media $handheld {
      padding-top: 50px;
      padding-bottom: 60px;
    }
  }

  .your-actions-section--caption {
    @extend .caption;
    text-transform: uppercase;
    margin-bottom: 50px;
    text-align: center;

    &:after {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .your-actions-section--list {
    display: flex;
    justify-content: space-between;
    font-weight: normal;
    text-align: center;
    line-height: 1.75;
    padding-left: 10%;
    padding-right: 10%;

    @media $small-mobile {
      flex-direction: column;

      .separator + .item {
        margin-top: 20px;
      }
    }

    .item--icon {
      height: 120px;
      margin-bottom: 30px;

      @media $small-mobile {
        margin-bottom: 10px;
      }
    }

    for $item in 1..3 {
      .item_{$item} .item--icon {
        background: url('/i/partitions-action-' + $item + '.png') no-repeat 50%;
      }
    }

    .separator {
      position: relative;

      @media $small-mobile {
        display: none;
      }

      &:before {
        content: '';
        display: block;
        width: 15px; height: 15px;
        border-top: 2px solid $black;
        border-right: 2px solid $black;
        position: absolute;
        left: 50%; top: 35%;
        transform: rotate(45deg) translate(-50%, -50%);
      }
    }
  }

  .partitions-order-section {
    background: $black;
    color: $white;

    .wrapper {
      padding-top: 45px;
      padding-bottom: 45px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media $small-mobile {
        flex-direction: column;
        text-align: center;
      }
    }
  }

  @media $not-mobile {
    .partitions-order-section_fixed {
      box-sizing: border-box;
      position: fixed;
      bottom: 0; left: 0;
      z-index: 100;
      width: 100%;
      max-height: 125px;
      overflow: hidden;
      transition: all .5s ease;

      .wrapper {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }

    .inview + .partitions-order-section_fixed {
      opacity: 0;
      max-height: 0;
    }
  }

  .partitions-order-section--caption {
    font-size: 2.4rem;
    text-transform: uppercase;
    font-weight: normal;
    margin-bottom: 10px;
    letter-spacing: 1px;

    @media $small-mobile {
      font-size: 1.8rem;
    }
  }

  .partitions-order-section--text {
    letter-spacing: 1px;
  }

  .partitions-order-section--btn {
    @extend .btn, .white-bordered-btn;
    line-height: 49px;
    width: 321px;
    font-size: 1.6rem;
    text-transform: lowercase;
    font-variant: small-caps;

    @media $small-mobile {
      width: auto;
      padding-left: 40px;
      padding-right: 40px;
      margin-top: 20px;
    }
  }

  .partitions-video-section {
    position: relative;
    overflow: hidden;

    video {
      position: absolute;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
      min-width: 100%; min-height: 100%;
    }

    .wrapper {
      height: 672px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      z-index: 10;
      color: $white;

      @media $tablet {
        height: 550px;
      }

      @media $small-mobile {
        height: 350px;
      }
    }
  }

  .partitions-video-section--caption {
    @extend .caption;
    font-size: 6rem;
    line-height: 1;
    text-transform: lowercase;
    margin-bottom: 40px;
    padding-bottom: 40px;

    @media $small-handheld {
      font-size: 4rem;
    }

    @media $small-mobile {
      font-size: 3rem;
    }

    &:after {
      background: $white;
    }
  }

  .partitions-video-section--text {
    font-size: 2.4rem;
    line-height: 1.5;

    @media $small-mobile {
      font-size: 1.8rem;
    }
  }

  .partitions-examples-section {
    // padding-top: 100px;
    padding-bottom: 30px;

    @media $mobile {
      // padding-top: 50px;
      padding-bottom: 20px;
    }
  }

  .partitions-examples-section--caption {
    @extend .caption;
    text-transform: uppercase;
    margin-bottom: 50px;
    text-align: center;

    &:after {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .partitions-examples-section--slider {
    .tos-slide {
      @media $not-small-mobile {
        width: calc(33.3333% - 9.5px) !important;

        + .tos-slide {
          margin-left: 10px;
        }
      }
    }

    .tos-uibg {
      display: none;
    }

    .tos-wrapper {
      padding-bottom: 60px;
    }

    .tos-wrapper.tos-has-bullets .tos-pagination {
      width: auto; height: auto;
      left: auto; top: auto; bottom: 0; right: 0;
      overflow: visible;
      opacity: 1;

      a {
        width: 12px; height: @width;
        margin: 0 7px;
        background: $black;
        opacity: .3;
        transition: opacity .25s ease;

        @media $small-mobile {
          width: 8px; height: @width;
          margin: 0 3px;
        }

        &.tos-selected {
          opacity: 1;
        }

        @media $desktop {
          &:nth-child(3n+2), &:nth-child(3n+3) {
            display: none;
          }
        }
      }

      &:before, &:after {
        display: none;
      }
    }

    .item {
      position: relative;

      &:after {
        content: '';
        display: block;
        width: 100%; height: 100%;
        background: rgba($black, .5) url(/i/more-icon.png) no-repeat 50%;
        position: absolute;
        left: 0; top: 0;
        opacity: 0;
        transition: all .25s ease;
      }

      &:hover:after {
        opacity: 1;
      }
    }
  }

  .partitions-responses-section {
    padding-top: 30px;
    padding-bottom: 50px;
  }

  .partitions-responses-section--caption {
    @extend .caption;
    text-transform: uppercase;
    margin-bottom: 50px;
    text-align: center;

    &:after {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .partitions-responses-section--slider {
    max-width: calc(100vw - 60px);

    .tos-slide {
      vertical-align: top;
    }

    .item {
      display: flex;
      align-items: center;
      border: 1px solid rgba($black, .05);
      line-height: 0;

      @media $small-mobile {
        flex-direction: column;
      }
    }

    .item--photo {
      width: 33.33%;
      flex-shrink: 0;

      @media $small-mobile {
        width: 100%;
      }
    }

    .item--content {
      box-sizing: border-box;
      width: 100%;
      padding: 40px 45px 40px 55px;
      font-size: 1.4rem;
      font-weight: normal;
      line-height: 2;

      @media $small-mobile {
        padding: 20px;
      }
    }

    .item--text {
      margin-bottom: 35px;

      @media $mobile {
        line-height: 1.5;
      }

      &:before {
        content: '';
        background: url(/i/quote.png) no-repeat 50%;
        display: inline-block;
        width: 31px; height: 26px;
        margin-right: 15px;
      }
    }

    .item--name {
      text-align: right;
      text-transform: uppercase;
      margin-bottom: 5px;
    }

    .item--company {
      text-align: right;
      font-weight: $light;
    }

    .tos-uibg {
      display: none;
    }

    .tos-wrapper {
      padding-bottom: 60px;
    }

    .tos-wrapper.tos-has-bullets .tos-pagination {
      width: auto; height: auto;
      left: auto; top: auto; bottom: 0; right: 0;
      overflow: visible;
      opacity: 1;

      @media $mobile {
        width: inherit;
      }

      a {
        width: 12px; height: @width;
        margin: 0 7px;
        background: $black;
        opacity: .3;
        transition: opacity .25s ease;

        &.tos-selected {
          opacity: 1;
        }
      }

      &:before, &:after {
        display: none;
      }
    }
  }

  .partitions-calculator-section {
    background: $almost-white;
    padding-top: 80px;
    padding-bottom: 100px;

    @media $mobile {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }

  .partitions-calculator-section--caption {
    @extend .caption;
    text-transform: uppercase;
    margin-bottom: 30px;
    text-align: center;
  }

  .partitions-calculator-section--text {
    margin-bottom: 50px;

    @media $small-mobile {
      br {
        display: none;
      }
    }
  }

  .partitions-calculator {
    display: flex;
    justify-content: space-between;

    @media $small-mobile {
      flex-direction: column;
    }

    .product-card--variants-list .item--icon {
      background-size: cover;
    }
  }

  .partitions-calculator--left {
    @media $not-small-mobile {
      padding-top: 40px;
      width: 45%;

      @media $tablet {
        width: 40%;
      }
    }

    @media $small-mobile {
      box-sizing: border-box;
      display: none;
      margin-top: 0;
      position: fixed;
      left: 0; bottom: 0;
      width: 100%;
      background: $almost-white;
      padding: 0 20px 10px;
      text-align: center;
      z-index: 100;

      &.is-active {
        display: block;
      }
    }
  }

  @media $not-small-mobile {
    .partitions-calculator--right {
      width: 45%;

      @media $tablet {
        width: 55%;
      }
    }
  }

  .partitions-calculator--group {
    max-width: 500px;
  }

  .partitions-calculator--group + .partitions-calculator--group {
    margin-top: 35px;
  }

  .partitions-calculator--slider {

    .partition-variant{
      max-height: 315px;
    }
    .tos-next, .tos-prev {
      opacity: 1;
      background: none;
      height: 40px;

      span {
        display: none;
      }

      &:before {
        content: '';
        display: block;
        width: 14px; height: @width;
        border-top: 2px solid $black;
        position: absolute;
        left: 50%; top: 50%;
      }
    }

    .tos-prev:before {
      border-left: 2px solid $black;
      transform: rotate(-45deg) translate(-50%, 50%);
      margin-top: -19px;
      margin-left: -4px;
    }

    .tos-next:before {
      border-right: 2px solid $black;
      transform: rotate(45deg) translate(-50%, 50%);
      margin-top: -8px;
    }

    @media $small-mobile {
      display: none;
    }
  }

  .partitions-calculator--caption {
    font-size: 1.8rem;
    font-weight: bold;
    font-variant: small-caps;
    text-transform: lowercase;
    padding-bottom: 10px;
    border-bottom: 1px solid $light-gray;
    margin-bottom: 30px;
    position: relative;

    @media $small-mobile {
      font-size: 1.6rem;
    }

    /* &:after {
      content: '';
      display: block;
      width: 31px; height: 2px;
      background: $black;
      position: absolute;
      left: 0; bottom: 0;
    } */
  }

  .partitions-calculator--price {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 45px;
    border-top: 1px solid $light-gray;
    padding-top: 15px;
    font-size: 1.4rem;
    font-weight: normal;
    opacity: 0;
    transition: opacity .25s ease;

    @media $small-mobile {
      margin-top: 0;
      text-align: left;
      border: none;
      font-size: 1.1rem;
      padding-top: 7px;
    }

    &.is-active {
      opacity: 1;

      @media $small-mobile {
        display: flex;
      }
    }

    /* @media $small-mobile {
      box-sizing: border-box;
      display: none;
      margin-top: 0;
      position: fixed;
      left: 0; bottom: 0;
      width: 100%;
      background: $almost-white;
      padding: 10px 20px;
      text-align: center;
      z-index: 100;
    } */
  }

  .partitions-calculator--price + .partitions-calculator--price {
    margin-top: 0;
    border-top: 0;
  }

  .partitions-calculator--price-value {
    font-size: 3rem;
    line-height: 1;
    font-weight: bold;
    font-variant: small-caps;
    white-space: nowrap;

    &.small {
      font-size: 2.4rem;
    }

    @media $small-mobile {
      font-size: 1.8rem;

      &.small {
        font-size: 1.6rem;
      }
    }
  }

  .partitions-calculator--variants {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .item {
      padding-top: 80px;
      /* text-align: center; */
      font-size: 1.4rem;
      font-weight: normal;
      line-height: 1.2;
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 100%; height: 100%;
        position: absolute;
        left: 0; top: 0;
      }

      @media $small-mobile {
        font-size: 1.2rem;
      }

      &:not(.checked) {
        &:before {
          opacity: .25;
        }

        .item--name {
          color: rgba($black, .25);
        }
      }
    }

    .item--name .hint {
      color: $black;
    }

    for $item in 1..4 {
      .item_{$item}:before {
        background: url('/i/calculator-variant-' + $item + '.png') no-repeat 50% 0;
      }
    }

    .item_4:before {
      background: url('/i/calculator-variant-4-2.png') no-repeat 50% 0;
    }
  }

  .partitions-calculator--profiles {
    .item {
      width: 30%;
      padding-top: 140px;

      @media $small-handheld {
        padding-top: 115px;
      }

      @media $small-mobile {
        padding-top: 95px;
      }

      &:before {
        height: calc(100% - 25px);
      }
    }

    for $item in 1..3 {
      .item_{$item}:before {
        background: url('/i/profile-' + $item + '.jpg') no-repeat 0 0 / contain;
      }
    }
  }

  .partitions-calculator--dimensions {
    display: flex;
  }

  .partitions-calculator--dimension-field {
    flex: 1;

    .inp {
      border: 2px solid $gray;
      text-align: center;
      font-size: 1.4rem;
      line-height: 45px;
      padding-top: 0;
      padding-bottom: 0;

      @media $small-mobile {
        font-size: 1.3rem;
      }
    }
  }

  .partitions-calculator--dimension-field + .partitions-calculator--dimension-field {
    margin-left: 45px;
    position: relative;

    &:before {
      content: 'Х';
      display: block;
      font-size: 1.4rem;
      font-weight: bold;
      color: $gray;
      width: 45px;
      text-align: center;
      position: absolute;
      left: -45px; top: 50%;
      transform: translateY(-50%);

      @media $small-mobile {
        font-size: 1.3rem;
      }
    }
  }

  .partitions-calculator--radio-list {
    display: flex;
    justify-content: space-between;
  }

  .partitions-calculator--radio {
    font-size: 1.4rem;
    font-weight: normal;
    padding-right: 25px;
    position: relative;

    @media $small-mobile {
      font-size: 1.2rem;
    }

    &:after {
      content: '';
      display: block;
      width: 12px; height: 10px;
      background: url(/i/check.png);
      position: absolute;
      right: 0; top: 50%;
      transform: translateY(-50%);
      opacity: 0;
      transition: opacity .25s ease;
    }

    &.checked:after {
      opacity: 1;
    }
  }

  .partitions-calculator--radio:not(.checked) {
    opacity: .25;
  }

  .partitions-calculator--radio:hover {
    opacity: 1;
  }

  .partitions-calculator--radio input {
    display: none;
  }

  .partitions-calculator--subcaption {
    font-size: 1.4rem;
    font-weight: normal;
    opacity: .25;

    @media $small-mobile {
      font-size: 1.3rem;
      opacity: 1;
    }
  }

  .product-card--extra-params .product-card--variants-list .item {
    @media $small-mobile {
      flex-basis: 27%;
      max-width: 75px;
      min-width: 0;
    }
  }

  .partitions-calculator--group_glasses {
    /* border-top: 1px solid $light-gray; */
    padding-top: 25px;
    /* display: flex;
    justify-content: space-between;
    align-items: flex-start; */
    display: block;

    .product-card--variants-list {
      flex-wrap: wrap;

      .item {
        flex-basis: calc(33.33% - 15px);
        max-width: 220px;
        min-width: 0;
        margin-right: 15px;
        margin-bottom: 15px;
        cursor: default;

        @media $small-mobile {
          max-width: calc(33.33% - 15px);
          margin-left: 0;
        }

        &:before {
          // padding-bottom: 65%;
          pointer-events: none;
          z-index: 11;
        }

        &:hover {
          z-index: 15;
        }
      }

      .item + .item {
        margin-left: 0;
      }

      .item--icon {
        background-size: cover;
        background-repeat: no-repeat;
        padding-bottom: 100%;
        z-index: 10;
        border: 2px solid $black;

        &:after {
          content: '';
          display: block;
          width: 200%;
          padding-bottom: 0;
          background: inherit;
          position: absolute;
          left: 50%; bottom: 100%;
          transform: translateX(-50%);
          margin-bottom: 5px;
          max-height: 0;
          overflow: hidden;
          opacity: 0;
          transition: all .25s ease;

          @media $handheld {
            display: none;
          }
        }

        &:hover:after {
          opacity: 1;
          padding-bottom: 150%;
        }
      }

      .item--caption {
        font-size: 1.3rem;
        text-align: left;
        margin-top: 1em;

        @media $small-mobile {
          font-size: 1.2rem;
        }
      }

      .item--variants li {
        list-style: none;
      }

      .item--variants .radio {
        padding-left: 20px;
        padding-top: 0;
        font-size: 1.2rem;

        &:before {
          width: 12px; height: @width;
        }

        &:after {
          width: 8px; height: @width;
          left: 2px; top: 2px;
        }
      }
    }

    @media $small-mobile {
      flex-direction: column;

      .group + .group {
        margin-top: 20px;
      }
    }

    @media $portrait-tablet {
      .group + .group {
        margin-left: 20px;
      }
    }

    .partitions-calculator--radio-list {
      flex-direction: column;
    }

    .partitions-calculator--radio {
      margin-top: 15px;
      padding-right: 15px;

      @media $portrait-tablet {
        padding-right: 15px;
      }

      /* padding-right: 0;

      &:after {
        display: none;
      } */
    }
  }

  .other-color-input {
    font-size: 1.2rem;
    border: 2px solid #929292;
    line-height: 30px;
    width: 130px;
    padding: 0 10px;
    margin-bottom: 30px;
  }

  .partitions-calculator--footer {
    display: flex;

    @media $small-mobile {
      flex-direction: column;
    }
  }

  .partitions-order-section--submit-btn {
    @extend .btn, .orange-btn;
    line-height: 49px;
    padding-bottom: 2px;
    font-size: 1.8rem;
    text-transform: lowercase;
    font-variant: small-caps;
    font-weight: bold;
    margin-top: 30px;
    color: $black;
    flex: 1;
    margin-left: 15px;

    @media $small-mobile {
      width: 100%;
      margin-top: 20px;
      margin-left: 0;
    }
  }

  .partitions-order-section--fast-order-btn {
    @extend .btn, .black-btn;
    line-height: 49px;
    padding-bottom: 2px;
    font-size: 1.8rem;
    text-transform: lowercase;
    font-variant: small-caps;
    font-weight: bold;
    margin-top: 30px;
    flex: 1;

    @media $small-mobile {
      width: 100%;
      margin-top: 20px;
    }
  }

  .hint {
    display: inline-block;
    width: 17px; height: @width;
    vertical-align: middle;
    margin-top: -3px;
    margin-left: 5px;
    background: url(/i/hint-icon.png) no-repeat 50%;
    position: relative;
    z-index: 10;

    @media $mobile {
      display: none;
    }
  }

  .hint-content {
    display: none;
    position: absolute;
    left: 50%; top: 100%;
    transform: translateX(-50%);
    margin-top: 10px;
    font-size: 1.1rem;
    line-height: 1.35;
    border: 1px solid $black;

    @media $handheld {
      left: auto; right: 0;
      transform: none;
    }

    img {
      width: 200px;
      max-width: 200px;
    }
  }

  .hint:hover .hint-content {
    display: block;
    background: $white;
    padding: 10px;
  }

  .page-menu {
    position: fixed;

    @media only screen and (min-width: 1601px) {
      left: 5%;
      top: 50%;
      transform: translateY(-50%);

      li {
        list-style: none;
      }

      li + li {
        margin-top: 20px;
      }

      a {
        text-decoration: none;
        color: #7f7f7f;
        font-size: 1.2rem;
        font-weight: normal;
        position: relative;
        padding-left: 25px;

        span {
          opacity: 0;
          transition: opacity .25s ease;
        }

        &:before {
          content: '';
          display: block;
          width: 6px;
          height: @width;
          border-radius: 50%;
          background: #7f7f7f;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          transition: all .25s ease;
        }

        &:hover {
          span {
            opacity: 1;
          }
        }
      }

      .is-active a {
        &:before {
          width: 9px;
          height: 9px;
        }

        span {
          opacity: 1;
        }
      }

      &.black-skin .is-active a {
        color: $black;

        &:before {
          background: $black;
        }
      }

      &.white-skin .is-active a {
        color: $white;

        &:before {
          background: $white;
        }
      }
    }
    @media only screen and (max-width: 1780px) {
      left: 1%;
    }
    @media only screen and (max-width: 1600px) {
      left: 0; top: 112px;
      width: 100%;
      z-index: 20;

      ul{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;
      }
      li{
        display: flex;
        list-style: none;
        flex-grow: 1;
      }
      li:nth-of-type(2n + 1){
        background-color: #848486;
      }
      li:nth-of-type(2n + 1):after{
        content: '';
        width: 0; height: 0;
        border-style: solid;
        border-width: 12px 0 12px 8px;
        background-color: #9F9F9F;
        border-color: transparent transparent transparent #848486;
      }
      li:nth-of-type(2n) {
        background-color: #9F9F9F;
      }
      li:nth-of-type(2n):after{
        content: '';
        width: 0; height: 0;
        border-style: solid;
        border-width: 12px 0 12px 8px;
        background-color: #848486;
        border-color: transparent transparent transparent #9F9F9F;
      }
      li:last-of-type:after{
        background-color: #9F9F9F;
      }
      a {
        text-decoration: none;
        color: #ffffff;
        font-size: 1.2rem;
        font-weight: normal;
        position: relative;
        padding-top: 5px;
        padding-bottom: 5px;
        width: 100%;
        text-align: center;
      }
      .is-active a {
        background-color: black;
      }
      li.is-active:after{
        content: '';
        width: 0; height: 0;
        border-style: solid;
        border-width: 12px 0 12px 8px;
        border-color: transparent transparent transparent black;
      }
      li.is-active:last-of-type:after {
        background-color: black;
      }
      li.is-active:nth-of-type(2n + 1):before{
        content: '';
        width: 0; height: 0;
        border-style: solid;
        border-width: 12px 0 12px 8px;
        background-color: black;
        border-color: transparent transparent transparent #9F9F9F;
        margin-left: -12px;
      }
      li.is-active:nth-of-type(2n):before{
        content: '';
        width: 0; height: 0;
        border-style: solid;
        border-width: 12px 0 12px 8px;
        background-color: black;
        border-color: transparent transparent transparent #848486;
        margin-left: -12px;
      }
    }
    @media only screen and (max-width: 768px) {
      top: 54px;
    }
    @media only screen and (max-width: 568px) {
      display: none;
    }
  }
}

.partitions-page__catalog-more {
  font-weight: 700;
  padding: 20px 40px !important;
}

.partitions-quiz {
  padding: 80px 0;
  background: $black url(/i/partitions-1-2.jpg) no-repeat 50% / cover;

  @media (max-width: 1024px) {
    padding: 50px 0;
  }
}

.partitions-quiz__frame {
  width: 100%;
  height: 600px;

  @media (max-width: 1024px) {
    height: 500px;
  }

  @media (max-width: 812px) {
    height: 700px;
  }
}

.showroom-section {
  .black-btn {
    padding: 15px 0 !important;
    width: 321px;
    font-size: 1.6rem !important;
    text-transform: lowercase;
    font-variant: small-caps;
    justify-content: center;
  }
}
