
/*   -=========== Infopage ===========-   */

.infopage{

  // for headings
  .decorated{
    margin-bottom: 48px;
  }
  .decorated:before{
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    content: '';
    margin-top: 24px;
    border-bottom: 2px solid #000;
  }

  .text-container{
    font-weight: 400;
    letter-spacing: .66px;
    color: #3a3a3a;
    line-height: 1.5;
  }
}

.delivery-page {
  max-width: 600px;
  line-height: 1.5;

  .h3 {
    margin-top: 4em !important;
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 24px; height: 0;
      border-bottom: 2px solid #000;
      position: absolute;
      top: -1.5em;
    }
  }
}
