
/*   -=========== Layout ===========-   */

// Floats

.fl {
  float: left;
}

.fr {
  float: right;
}

.no-fl {
  float: none;
}

@media $not-small-mobile {
  .l-main {
    float: left;
    width: 49%;
  }

  .r-main {
    float: right;
    width: 49%;
  }
}

@media $small-mobile {
  .l-main + .r-main {
    margin-top: 10px;
  }
}


// Containers

.group, .nofloat {
  clearfix();
}

.no-overflow {
  overflow: hidden;
}

.flex-group {
  display: flex;

  &.justify {
    justify-content: space-between;
  }
}

.wrapper {
  box-sizing: border-box;

  @media only screen and (min-width: $wrapper-width + 20px) {
    max-width: $wrapper-width;
    margin-left: auto;
    margin-right: auto;
  }

  @media only screen and (max-width: $wrapper-width + 19px) {
    @media $not-mobile {
      margin-left: 30px;
      margin-right: 30px;
    }

    @media $mobile {
      margin-left: 25px;
      margin-right: 25px;
    }
  }
}

.wrapper-narrow{
  width: 900px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (max-width: 960px){
    width: calc(100% - 60px);
    margin-left: 30px;
    margin-right: 30px;
  }

  @media only screen and (max-width: 700px){
    width: calc(100% - 50px);
    margin-left: 25px;
    margin-right: 25px;
  }
}

.container-with-aside {
  display: flex;
  justify-content: space-between;

  @media $bigger-than-wrapper {
    width: 'calc(50vw + %s)' % ($wrapper-width / 2);
  }

  @media $less-than-wrapper {
    margin-left: 30px;
    margin-right: 30px;
  }
}

.left-aside {
  box-sizing: border-box;
  max-width: 400px;
  min-width: 320px;
  flex-basis: 30%;
  flex-shrink: 0;

  + .main-section {
    margin-left: 48px;
    flex: 1;
  }
}


// Margins

.m3 {
  margin-bottom: 3px;
}

.m7 {
  margin-bottom: 7px;
}

for $i in (0..20) {
  .m{$i * 5} {
    margin-bottom: 5px * $i;
  }
}


// Position

.relative {
  position: relative;
}

.static {
  position: static !important;
}


// Display

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.hidden {
  display: none !important;
}


// Devices display

@media $handheld {
  .only-desktop {
    display: none !important;
  }
}

@media $not-mobile {
  .only-mobile {
    display: none !important;
  }
}

@media $not-small-mobile {
  .only-small-mobile {
    display: none !important;
  }
}

@media $desktop {
  .not-desktop { // mobile + tablet
    display: none !important;
  }
}

@media $mobile {
  .not-mobile { // desktop + tablet
    display: none !important;
  }
}

@media $small-mobile {
  .not-small-mobile {
    display: none !important;
  }
}
