
/*   -=========== Forms ===========-   */

// Base

input,
textarea {
  font: inherit;
}

.form {
  span.required {
    color: $warning-color;
  }
}

.inp,
.textarea {
  box-sizing: border-box;
  width: 100%;
  padding: .3125em .15em;
  line-height: 1.25em;
  font-weight: $semi-bold;
  color: #373737;
  border: none;
  border-bottom: 1px solid #ded9e2;
  transition: all .25s ease;
  background: transparent;

  &:focus {
    border-color: $black;
  }

  .form-field.error > & {
    border-color: $warning-color;
    color: $warning-color;
  }

  &::placeholder {
    font-weight: normal;
  }
}

.textarea {
  min-height: 31px;
}

.radio-item + .radio-item {
  margin-top: 35px;
}

.radio {
  padding-left: 48px;
  padding-top: .25em;
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    box-sizing: border-box;
    display: block;
    width: 27px; height: @width;
    border: 1px solid $light-gray;
    border-radius: 50%;
    position: absolute;
    left: 0; top: 0;
  }

  &:after {
    content: '';
    display: block;
    width: 19px; height: @width;
    background: #373737;
    border-radius: 50%;
    position: absolute;
    left: 4px; top: 4px;
    opacity: 0;
    transition: opacity .25s ease;
  }

  &.checked:after {
    opacity: 1;
  }

  input {
    display: none;
  }
}

.form-row {
  clearfix();

  + .form-row {
    .one-column-form & {
      margin-top: 30px;
    }

    .two-columns-form & {
      margin-top: 1.25em;
    }
  }
}

.form-label {
  .one-column-form & {
    margin-bottom: .15em;
  }

  .two-columns-form & {
    float: left;
  }
}

.form-field {
  position: relative;

  .two-columns-form & {
    float: right;
  }

  .errorMessage {
    display: none;
    font-size: 1.2rem;
    line-height: 1em;
    padding: 1px 0 3px;
    text-align: center;
    background: $warning-color;
    color: $white;
    position: absolute;
    width: 100%;

    .two-columns-form & {
      position: absolute
      left: 0; bottom: 0;
      transform: translateY(100%);
      width: 100%;
    }
  }

  & + .errorMessage {
    color: $warning-color;
    font-weight: 700;
    font-size 1.2rem;
    margin-right: 15px;
  }

  &.error {
    margin-bottom: .25em;

    .errorMessage {
      display: block;
    }
  }
}

.form-field_radio.error .errorMessage {
  top: calc(100% + 10px);
}

.form-placeholder {
  position: absolute;
  left: 0; top: 50%;
  transform: translateY(-50%);
  font-size: 1.6rem;
  color: $gray;
  white-space: nowrap;
  transition: all .25s ease;
  pointer-events: none;

  .form-field.is-touched &,
  .form-field_file & {
    top: -5px;
    font-size: 1rem;
  }
}

.form-field_file {
  .inp {
    font-size: 1.4rem;
    font-weight: normal;
    border-bottom: none;
  }
}

.form-submit {
  .one-column-form & {
    margin-top: 55px;
  }

  .two-columns-form & {
    float: right;
  }

  .submit-btn {
    @extend .btn, .black-btn, .h49btn;
    width: 210px;
    font-size: 1.6rem;
    font-weight: bold;
    font-variant: small-caps;
    text-transform: lowercase;
  }
}

.form-hint {
  font-size: 1.2rem;
  color: #3a3a3a;

  &:not(.form-hint_reversed) {
    margin-top: 40px;
  }

  &.form-hint_reversed {
    margin-bottom: 30px;
  }
}

.order-form {
  max-width: 650px;
}

.order-form--group + .order-form--group {
  margin-top: 80px;
}

.order-form--caption {
  font-size: 2.4rem;
  font-weight: bold;
  font-variant: small-caps;
  text-transform: lowercase;
  padding-bottom: 10px;
  position: relative;
  margin-bottom: 30px;

  @media $mobile {
    font-size: 2rem;
  }

  span {
    font-size: 1.6rem;
  }

  &:after {
    content: '';
    display: block;
    width: 79px; height: 2px;
    background: $black;
    position: absolute;
    left: 0; top: 100%;
  }
}

.order-form .form-field:not(.form-field_long) {
  max-width: 450px;
}

.order-form .form-field_long .form-placeholder {
  font-size: 1.4rem;
}

.promocode-form {
  @media $not-mobile {
    width: ($wrapper-width / 2) - 80px;
    max-width: calc(50vw - 80px);
    display: flex;
    margin-bottom: 5px;
    flex-wrap: wrap;
  }

  @media $mobile {
    margin-bottom: 20px;
  }
}

.promocode-form--error {
  width: 100%;
  font-size: 1.2rem;
  padding-left: 20px;
  margin-bottom: 5px;
}

.promocode-form--body {
  flex: 1;
  display: flex;
  align-items: center;

  @media $handheld {
    padding-left: 15px;
    padding-right: 10px;
  }

  @media $not-mobile {
    border: 2px solid $white;
    padding: 10px 15px 10px 20px;
  }

  @media $mobile {
    padding: 10px 0;
  }
}

.promocode-form--label {
  font-size: 1.6rem;
  font-variant: small-caps;
  text-transform: lowercase;
  font-weight: normal;

  @media $handheld {
    font-size: 1.4rem;
  }
}

.promocode-form--field {
  flex: 1;
  margin-left: 10px;

  .inp {
    width: 100%;
    text-align: center;
    font-size: 1.6rem;
    font-weight: normal;
    font-variant: small-caps;
    text-transform: lowercase;

    @media $handheld {
      font-size: 1.4rem;
    }
  }
}

.promocode-form--submit {
  @media $not-mobile {
    margin-left: 15px;
  }

  @media $mobile {
    text-align: center;
  }

  .submit-btn {
    @extend .btn, .black-bordered-btn;
    width: 130px; height: 100%;
    font-size: 1.6rem;
    font-weight: bold;
    font-variant: small-caps;
    text-transform: lowercase;

    @media $mobile {
      height: 39px;
    }
  }
}

.callback-popup,
.feedback-popup {
  .form-submit {
    text-align: center;
    margin-top: 30px;
  }
}

.order-size-form {
  .form-group {
    padding: 10px;
    border: 1px solid $light-gray;
    margin-bottom: 30px;

    .form-hint {
      clear: left;
      font-weight: normal;
      font-size: 1.3rem;
    }
  }

  .form-group--caption {
    float: left;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .form-row_double {
    margin-top: -10px;

    .form-field.error {
      margin-bottom: 0;
    }
  }

  .form-result {
    display: none;
    margin-top: 20px;
    font-variant: small-caps;
    font-weight: bold;
    text-transform: lowercase;
  }

  .form-submit {
    margin-top: 40px;
  }
}

.form-row_double {
  display: flex;
  justify-content: space-between;

  &:before, &:after {
    display: none;
  }

  .form-field {
    width: calc(50% - 5px);
  }
}

.design-order-form {
  max-width: 600px;
}
