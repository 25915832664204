
/*   -=========== Order ===========-   */

.order-page {
  @media $not-mobile {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
  }

  @media $mobile {
    padding-top: 20px;
  }
}

@media $not-mobile {
  .order-page--left,
  .order-page--right {
    width: calc(50% - 22px);
  }
}

@media $mobile {
  .order-page--right {
    margin-top: 50px;
  }
}

.order-section--caption {
  font-size: 1.6rem;
  font-weight: bold;
  font-variant: small-caps;
  text-transform: lowercase;
  line-height: 1.2;
  position: relative;
  letter-spacing: 1px;
  padding-bottom: 10px;
  margin-bottom: 35px;

  &:before, &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0; top: 100%;
  }

  &:before {
    width: 100%; height: 1px;
    background: #c1c1c1;
  }

  &:after {
    width: 79px; height: 2px;
    background: #373737;
    margin-top: -1px;
  }
}

.order-products {
  margin-bottom: 40px;

  .product + .product {
    margin-top: 30px;
  }

  .product {
    border: 2px solid transparent;
    position: relative;
    transition: border-color .25s ease;

    @media $not-small-handheld {
      display: flex;
      justify-content: space-between;
    }
  }

  html:not(.is-touch-device) & .product:hover {
    border-color: $black;

    .product--remove-link {
      border-color: $black;

      &:before, &:after {
        background: $black;
      }
    }
  }

  .product--image {
    position: relative;
    flex: .4;
    line-height: 0;
    transition: opacity .25s ease;

    @media $not-small-handheld {
      padding-top: 10px;
      padding-left: 10px;
      padding-bottom: 10px;
    }

    @media $small-handheld {
      text-align: center;
    }
  }

  .product--article {
    width: 63px; height: @width;
    text-align: center;
    background: $white;
    border-radius: 50%;
    position: absolute;
    right: 10px; top: 10px;
    font-size: 1.4rem;
    font-weight: bold;
    font-variant: small-caps;
    text-transform: lowercase;
    line-height: 1.35;

    @media $small-handheld {
      right: auto; left: 0; top: 0;
    }

    .product--article-label {
      position: absolute;
      bottom: 50%; left: 50%;
      transform: translateX(-50%);
    }

    .product--article-value {
      position: absolute;
      top: 50%; left: 50%;
      transform: translateX(-50%);
    }
  }

  .product--content {
    flex: .6;
    padding-top: 15px;
    padding-bottom: 15px;

    @media $not-small-handheld {
      margin-left: 32px;
    }
  }

  .product--type {
    font-size: 1.6rem;
    font-variant: small-caps;
    text-transform: lowercase;
  }

  .product--name {
    @extend .h1;
    margin-bottom: 15px;

    @media $not-small-handheld {
      padding-right: 60px;
    }

    @media $handheld {
      line-height: 1.2;
    }

    a {
      text-decoration: none;
    }
  }

  .product--price-block {
    margin-bottom: 20px;
  }

  .product--price,
  .produсt--old-price {
    @extend .h1;
    display: inline-block;
    white-space: nowrap;
  }

  .product--price-value {
    font-weight: normal;
  }

  .product--price-currency {
    font-weight: bold;
  }

  .produсt--old-price {
    font-weight: $light;
    text-decoration: line-through;
    color: $light-gray;;
    margin-left: 5px;

    &:before {
      content: '/';
      display: inline-block;
      margin-right: 9px;
    }
  }

  .product--params {
    font-size: 1.6rem;
    font-weight: bold;
    font-variant: small-caps;
    text-transform: lowercase;

    .item {
      display: flex;
    }

    .item--label {
      color: $light-gray;
      letter-spacing: 1px;
    }

    .item--value {
      margin-left: 10px;
    }
  }

  .product--params + .product--params-list {
    margin-top: 15px;
  }

  .product--params-list-caption {
    display: inline-block;
    font-size: 1.6rem;
    font-weight: bold;
    font-variant: small-caps;
    text-transform: lowercase;
    letter-spacing: 1px;
    color: $light-gray;
    padding-bottom: 5px;
    border-bottom: 1px solid $light-gray;
    cursor: pointer;
  }

  .product--params-content {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: all .25s ease;
  }

  .product--params-list.is-expanded {
    .product--params-content {
      padding-top: 10px;
      max-height: 500px;
      opacity: 1;
    }

    .product--params-list-caption {
      width: calc(100% - 20px);
    }
  }

  .product--params-table {
    font-size: 1.2rem;

    th {
      text-align: left;
    }

    th, td {
      padding: 3px 0;
    }

    td {
      padding-left: 20px;
    }
  }

  .product--remove-link {
    box-sizing: border-box;
    display: block;
    width: 37px; height: @width;
    border: 3px solid $light-gray;
    border-radius: 50%;
    position: absolute;
    right: 20px; top: 20px;
    font-size: 0;
    transition: border-color .25s ease;

    @media $small-handheld {
      right: 0; top: 0;
    }

    &:before, &:after {
      content: '';
      display: block;
      width: 3px; height: 18px;
      background: $light-gray;
      position: absolute;
      left: 50%; top: 50%;
      margin-left: -1px;
      margin-top: -9px;
      transition: background-color .25s ease;
    }

    &:before {
      transform: rotate(-45deg);
    }

    &:after {
      transform: rotate(45deg);
    }
  }
}

.order-summary {
  box-sizing: border-box;
  background: #e8e8e8;

  @media $not-mobile {
    position: fixed;
    width: 50%;
    padding: 25px 30px 30px;
  }

  @media $mobile {
    padding: 15px 30px 50px;
    margin-left: -25px;
    margin-right: -25px;
  }
}

.order-summary--caption {
  @extend .h1;
  margin-bottom: 5px;
}

.order-summary--table {
  max-width: 320px;
  margin-bottom: 25px;

  th, td {
    padding: 10px 0;
    vertical-align: bottom;
  }

  th {
    font-size: 1.6rem;
    text-align: left;
    font-weight: $light;
    padding-bottom: 11px;
    letter-spacing: 1px;
  }

  td {
    padding-left: 10px;
    font-size: 2.4rem;
    font-weight: $semi-bold;
    font-variant: small-caps;
    text-transform: lowercase;
    white-space: nowrap;
  }
}

.order-submit-doubler {
  @extend .btn, .black-btn, .h49btn;
  width: 210px;
  font-size: 1.6rem;
  font-weight: bold;
  font-variant: small-caps;
  text-transform: lowercase;

  @media $mobile {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
