
/*   -=========== Product card ===========-   */

.product-card {
  display: flex;

  @media $mobile {
    flex-direction: column;
  }
}

.product-card--image {
  line-height: 0;
  position: relative;
  z-index: 10;

  @media $not-mobile {
    flex-basis: 50%;
    text-align: right;
    max-width: 50vw;
  }

  @media $mobile {
    max-width: 100vw;
  }

  .slick-slide img {
    display: inline-block;
  }

  .slick-dots {
    position: absolute;
    margin-top: -50px;
    width: auto; height: auto;
    left: auto; bottom: 5%; right: 5%;
    overflow: visible;
    opacity: 1;

    li {
      display: inline-block;
      list-style: none;
      width: 12px; height: @width;
      border-radius: 50%;
      margin: 0 7px;
      background: $black;
      opacity: .3;
      transition: opacity .25s ease;

      &.slick-active {
        opacity: 1;
      }
    }

    li button {
      width: 100%; height: 100%;
      font-size: 0;
      background: transparent;
    }
  }

  .slick-arrow {
    width: 40px; height: 40px;
    background: $black;
    font-size: 0;
    position: absolute;
    top: 50%;
    z-index: 1000;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity .25s ease;

    &:before {
      content: '';
      display: block;
      width: 12px; height: @width;
      border-top: 2px solid $white;
      position: absolute;
      top: 50%;
      margin-top: -6px;
    }
  }

  &:hover .slick-arrow {
    opacity: .5;

    &:hover {
      opacity: 1;
    }

    &.slick-disabled {
      opacity: .2;
    }
  }

  .slick-prev {
    left: 15px;

    &:before {
      left: 50%;
      border-left: 2px solid $white;
      transform: rotate(-45deg);
      margin-left: -3px;
    }
  }

  .slick-next {
    right: 15px;

    &:before {
      right: 50%;
      border-right: 2px solid $white;
      transform: rotate(45deg);
      margin-right: -3px;
    }
  }
}

.product-card--slider {
  white-space: nowrap;
  overflow: hidden;

  .item {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    text-align: center;
  }
}

.product-card--icons {
  position: absolute;
  right: 6%; top: 80px;
  z-index: 1000;

  @media $small-mobile {
    top: 25px;
  }

  .item {
    width: 63px; height: @width;
    background: $white;
    border-radius: 50%;
    position: relative;

    @media $small-mobile {
      width: 45px; height: @width;
    }
  }

  .item + .item {
    margin-top: 20px;

    @media $small-mobile {
      margin-top: 10px;
    }
  }
}

.product-card--article {
  font-size: 1.4rem;
  font-weight: bold;
  font-variant: small-caps;
  text-transform: lowercase;
  line-height: 1.35;
  text-align: center;

  @media $small-mobile {
    font-size: 1.2rem;
  }
}

.product-card--article-label {
  position: absolute;
  bottom: 50%; left: 50%;
  transform: translateX(-50%);
}

.product-card--article-value {
  position: absolute;
  top: 50%; left: 50%;
  transform: translateX(-50%);
  margin-top: -3px;
  white-space: nowrap;
}

.product-card--fav-link {
// кнопка временно скрыта refs #47167
  display: none;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    width: 100%; height: 100%;
    position: absolute;
    left: 0; top: 0;
    margin-top: 2px;
    background: url(/i/fav-icon.png) no-repeat 50%;

    @media $small-mobile {
      background-size: 55% auto;
    }
  }
}

.product-card--content {
  flex: 1;
  max-width: ($wrapper-width / 2);
  padding-left: 80px;
  padding-bottom: 30px;
  box-sizing: border-box;
  z-index: 11;

  @media $tablet {
    padding-left: 40px;
    max-width: calc(50vw - 35px);
  }

  @media $mobile {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100%;
  }
}

.product-card--type {
  font-size: 1.6rem;
  font-weight: normal;
  font-variant: small-caps;
  text-transform: lowercase;
  padding-top: 20px;
  margin-bottom: -5px;

  @media $tablet {
    padding-top: 10px;
  }
}

.product-card--name {
  margin-bottom: 65px;

  @media $handheld {
    margin-bottom: 30px;
  }

  @media $mobile {
    margin-bottom: 20px;
  }

  .product-card-mebel & {
    margin-bottom: 10px;
  }
}

.product-card--size {
  margin-top: -60px;
  margin-bottom: 30px;

  @media $handheld {
    margin-top: -25px;
    margin-bottom: 20px;
  }

  @media $mobile {
    margin-top: -20px;
    margin-bottom: 10px;
    font-size: 1.3rem;
  }

  .product-card--tabs & {
    margin-top: 0;
    margin-bottom: 15px;
  }
}

.product-card--tabs {
  /* margin-bottom: 85px; */

  .tabs-nav {
    margin-bottom: 15px;

    @media $small-handheld {
      li + li {
        margin-left: 15px;
      }

      a {
        font-size: 1.4rem;
      }
    }

    @media $small-mobile {
      a {
        font-size: 1.3rem;
      }
    }
  }

  @media $handheld {
    margin-bottom: 40px;
  }

  @media $mobile {
    margin-bottom: 20px;
  }
}

.product-card-postery {
  @media only screen and (min-width: 701px) and (max-width: 1150px) {
    .product-card--tabs {
      .tabs-nav{
        li{
          margin-left: 0px;
          display: flex;
        }
      }
    }
  }
}


.product-card--selector {
  display: inline-block;
  min-width: 210px;
  text-align: center;

  .selectric-items li {
    padding-right: 38px;
  }

  @media $small-mobile {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}

.product-card--dimensions {
  display: flex;

  @media $less-than-wrapper {
    @media $desktop {
      margin-right: 10px;
    }
  }

  @media $small-handheld {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.product-card--dimensions-caption,
.product-card--variants-caption {
  font-size: 1.3rem;
  font-weight: $semi-bold;
  min-width: 86px;

  @media $small-handheld {
    text-align: center;
    width: 100%;

    br {
      display: none;
    }
  }
}

.product-card--variants-caption {
  width: 100%;
}

.product-card--dimensions-field {
  position: relative;

  @media $not-small-handheld {
    margin-left: 45px;

    @media $tablet {
      margin-left: 15px;

      + .product-card--dimensions-field {
        margin-left: 45px;
      }
    }
  }

  @media $small-handheld {
    margin-top: 35px;
    min-width: 60%;
  }

  .inp {
    line-height: 43px;
    border: 3px solid $gray;
    padding: 0 20px;
    text-align: center;
  }

  + .product-card--dimensions-field {
    &:before {
      content: 'X';
      display: block;
      width: 20px; height: @width;
      line-height: @height;
      font-size: 1.4rem;
      font-weight: bold;
      text-align: center;
      position: absolute;
      top: 50%; right: 100%;
      transform: translateY(-50%);
      margin-right: 13px;

      @media $small-handheld {
        display: none;
        /* right: auto; left: 0;
        top: auto; bottom: 100%;
        transform: none;
        margin-bottom: 5px;
        margin-right: 0;
        width: 100%; */
      }
    }
  }
}

.product-card--dimensions-label {
  position: absolute;
  left: 50%; bottom: 100%;
  transform: translateX(-50%);
  margin-bottom: 10px;
  white-space: nowrap;
  font-size: 1.3rem;
  font-weight: normal;
  color: $gray;
}

.product-card--variants {
  display: flex;
  flex-wrap: wrap;

  @media $less-than-wrapper {
    @media $desktop {
      margin-right: 10px;
    }
  }

  @media $small-handheld {
    flex-wrap: wrap;
    justify-content: center;
  }

  .product-card--dimensions + & {
    margin-top: 30px;
  }

  /* + .product-card--variants {
    margin-top: 30px;
  } */
}

.product-card--variants-caption {
  margin-bottom: 10px;
}

.product-card--variants-list {
  display: flex;
  flex: 1;
  flex-wrap: wrap;

  /* @media $not-small-handheld {
    margin-left: 45px;

    @media $tablet {
      margin-left: 15px;
    }
  } */

  .item {
    flex: 1;
    min-width: 70px;
    max-width: 100px;
    position: relative;
    transition: box-shadow .25s ease;
    margin-right: 10px;
    margin-bottom: 15px;

    &:before {
      box-shadow: 0 0 5px rgba($black, .22);
    }

    @media $handheld {
      margin-right: 8px;
      margin-bottom: 8px;
    }

    @media $small-mobile {
      flex: 0 auto;
      flex-basis: calc(33.33% - 8px);
      max-width: 33%;
    }

    .product-card--variants_with-icons & {
      &:before {
        box-shadow: none;
      }

      &:before {
        box-shadow: 0 0 5px rgba($black, .22);
        transition: box-shadow .25s ease;
        z-index: 10;
      }

      &.is-active,
      &.checked {
        box-shadow: none;

        &:before {
          box-shadow: inset 0 0 0 2px $black;
        }

        &:after {
          opacity: 0;
        }
      }
    }

    &:not(.is-active),
    &[data-optional] {
      cursor: pointer;
    }

    &:before {
      content: '';
      display: block;
      padding-bottom: 100%;
      position: relative;
    }

    &:after {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: calc(100% - 4px);
      box-sizing: border-box;
      border: 2px solid $black;
      position: absolute;
      left: 0; top: 0;
      opacity: 0;
      transition: opacity .25s ease;
    }

    &.is-active {
      box-shadow: 0 0 0 $black;

      &:after {
        opacity: 1;
      }
    }
  }

  /* .item + .item {
    margin-left: 15px;

    @media $handheld {
      margin-left: 8px;
    }
  } */

  .item_variant-1:before {
    background: url(/i/wall-variant-1.jpg) no-repeat 50% / cover;
  }

  .item_variant-2:before {
    background: url(/i/wall-variant-2.jpg) no-repeat 50% / cover;
  }

  .item_variant-3:before {
    background: url(/i/wall-variant-3.jpg) no-repeat 50% / cover;
  }

  .item_variant-4:before {
    background: url(/i/wall-variant-4.jpg) no-repeat 50% / cover;
  }

  .item_variant-41:before {
    background: url(/i/wall-variant-41.jpg) no-repeat 50%;
  }

  .item_variant-42:before {
    background: url(/i/wall-variant-42.jpg) no-repeat 50%;
  }

  .item_variant-43:before {
    background: url(/i/wall-variant-43.jpg) no-repeat 50%;
  }

  .item_variant-44:before {
    background: url(/i/wall-variant-44.jpg) no-repeat 50%;
  }

  .item_variant-60:before {
    background: url(/i/wood-variant-60.jpg) no-repeat 50% / cover;
  }

  .item_variant-61:before {
    background: url(/i/wood-variant-61.jpg) no-repeat 50% / cover;
  }

  .item_variant-62:before {
    background: url(/i/wood-variant-62.jpg) no-repeat 50% / cover;
  }

  .item_variant-139:before {
    background: url(/i/wood-variant-139.jpg) no-repeat 50% / cover;
  }

  .item_variant-140:before {
    background: url(/i/wood-variant-140.jpg) no-repeat 50% / cover;
  }

  .item--icon {
    width: 100%;
    padding-bottom: 100%;
    position: absolute;
    left: 0; top: 0;
  }

  .item--caption {
    position: absolute;
    bottom: 50%; left: 50%;
    transform: translateX(-50%);
    font-size: 1.6rem;
    font-weight: bold;
    font-variant: small-caps;
    text-transform: lowercase;
    text-align: center;
    line-height: 1;
    margin-bottom: 5px;

    .product-card--variants_with-icons & {
      position: static;
      transform: none;
    }

    @media $handheld {
      font-size: 1.4rem;
    }

    @media $small-mobile {
      font-size: 1.2rem;
    }
  }

  .item--price {
    position: absolute;
    left: 50%; top: 50%;
    transform: translateX(-50%);
    font-size: 1.4rem;
    font-weight: bold;
    font-variant: small-caps;
    text-transform: lowercase;
    text-align: center;
    white-space: nowrap;
    margin-top: 5px;

    .product-card--variants_with-icons & {
      position: static;
      transform: none;
      margin-top: 5px;
    }

    @media $handheld {
      font-size: 1.3rem;
    }

    @media $small-mobile {
      font-size: 1.2rem;
    }
  }

  .item--preview-button,
  .item--hint-button {
    width: 16px; height: @width;
    line-height: @width;
    text-align: center;
    background: url(/i/zoom-icon.png) no-repeat 50%;
    position: absolute;
    right: 5px; top: 5px;
    z-index: 5;
    opacity: 0;
    transition: opacity .25s ease;

    @media $handheld {
      display: none;
    }
  }

  .item--hint-button {
    background: none;

    &:before {
      content: '?';
      color: $black;
      font-weight: bold;
      font-size: 2rem;
    }
  }

  .item:hover .item--preview-button,
  .item:hover .item--hint-button {
    opacity: .5;

    &:hover {
      opacity: 1;
    }
  }

  .item--preview,
  .item--hint {
    display: none;
    width: 300px; height: 300px;
    position: absolute;
    right: 0; bottom: 100%;
    margin-bottom: 6px;
    margin-right: -7px;
    border: 2px solid rgba($black, .5);
  }

  .item--hint {
    background-color: $white;
    width: auto; height: auto;
    padding: 5px;
  }

  .item--text {
    /* position: absolute;
    bottom: auto;
    top: 100%; right: auto; left: 50%;
    transform: translateX(-50%); */
    margin-bottom: auto;
    margin-top: 6px;
    width: 100%; height: auto;
    background: $white;
    border: none;
    font-size: 1.1rem;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.product-card--extra-params {
  /* margin-top: 35px; */

  .product-card--variants {
    flex-wrap: wrap;
  }

  .product-card--variants-caption {
    width: 100%;
  }

  .product-card--variants-list {
    margin-left: 0;
    flex-basis: 100%;
    padding-bottom: 0;
    flex-wrap: wrap;

    @media $small-handheld {
      flex-wrap: wrap;
    }

    @media $small-mobile {
      .item {
        min-width: 86px;
        max-width: 86px;
        margin: 5px;
      }
    }

    .item {
      max-width: 75px;

      @media $small-handheld {
        flex: inherit;

        @media $not-small-mobile {
          margin-bottom: 8px;
          margin-right: 8px;

          + .item {
            margin-left: 0;
          }
        }
      }
    }

    .item--caption {
      font-size: 1.2rem;
    }
  }
}

.product-card--extra-params-toggler {
  display: inline-block;
  font-weight: bold;
  position: relative;
  padding-right: 20px;
  line-height: 1.1;
  border-bottom: 1px dashed rgba($black, .5);
  cursor: pointer;
  transition: border-bottom-color .25s ease;

  &:hover {
    border-bottom-color: $black;
  }

  &:before, &:after {
    content: '';
    display: block;
    background: $black;
    position: absolute;
  }

  &:before {
    width: 13px; height: 3px;
    right: 0; top: 50%;
    transform: translateY(-50%);
  }

  &:after {
    width: 3px; height: 13px;
    right: 5px; top: 50%;
    transform: translateY(-50%);
    transition: all .25s ease;
  }
}

.product-card--extra-params-body {
  overflow: hidden;
  /* padding: 0 5px; */
  max-height: 0;
  opacity: 0;
  transition: all .25s ease;

  @media $small-mobile {
    padding-left: 0;
    padding-right: 0;
  }
}

.product-card--extra-params.is-expanded {
  .product-card--extra-params-body {
    max-height: 1500px;
    opacity: 1;
    padding: 0 5px;

    @media $small-mobile {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .product-card--extra-params-toggler:after {
    height: 0;
    opacity: 0;
  }
}

.product-card--description {
  line-height: 1.35;
  text-align: left;
  padding: 10px 10px 30px;
}


.product-card--price {
  display: flex;
  margin-bottom: 10px;

  .price {
    @extend .h1;
    font-weight: $semi-bold;
    color: $black;
  }

  .old-price {
    @extend .h1;
    font-weight: $light;
    text-decoration: line-through;
    color: $light-gray;
    margin-left: 9px;
    margin-top: 6px;
    font-size: 13px;

    &:before {
      content: '/';
      display: inline-block;
      margin-right: 9px;
    }
  }

  @media $small-mobile {
    justify-content: center;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 700px){

  .product-card--price {
    display: flex;
    flex-direction: column;

    .old-price{
      font-size: 16px;
      font-weight: 500;
      margin-left: 0;
      order: -1;

      &:before{
        content: '';
        margin-right: 0;
      }
    }
  }
}

.product-card--controls {
  display: inline-block;

  // @media $small-mobile {
  //   display: flex;
  //   align-items: center;
  //   flex-direction: column;
  // }
  /* display: flex;

  @media $small-handheld {
    flex-direction: column;
    align-items: flex-start;
  }

  @media $small-mobile {
    align-items: center;
  } */
}

.product-card--controls-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  // @media $not-small-handheld {
    flex-direction: row;
    flex-wrap: wrap;
  // }

  & + & {
    // @media $not-small-handheld {
      margin-left: 13px;
    // }

    // @media $small-handheld {
    //   margin-top: 13px;
    // }
  }
}

.product-card--buy-btn {
  @extend .btn, .black-btn, .h49btn;
  width: 210px;
  font-size: 1.6rem;
  font-weight: bold;
  font-variant: small-caps;
  text-transform: lowercase;
  letter-spacing: .05em;

  @media $small-handheld {
    flex-basis: calc(50% - 5px);
  }
}

.product-card--sizes-btn {
  @extend .btn, .black-bordered-btn, .h49btn;
  width: 210px;
  font-size: 1.6rem;
  font-weight: bold;
  font-variant: small-caps;
  text-transform: lowercase;
}

.product-card--fast-order-link {
  @extend .btn, .black-bordered-btn, .h49btn;
  display: inline-block;
  width: 210px;
  font-size: 1.1rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #3a3a3a;
  margin-top: 10px;
  text-decoration: none;
  border-bottom: 1px solid rgba(#3a3a3a, .5);
  transition: border-bottom-color .25s ease;
  letter-spacing: .05em;

  // @media $not-small-handheld {
    margin-top: 0;
    margin-left: 10px;
  // }

  &:hover {
    border-bottom-color: #3a3a3a;
  }

  @media $small-handheld {
    flex-basis: calc(50% - 5px);
  }
}

.custom-size-link {
  @extend .btn, .orange-btn;
  line-height: 49px;
  width: 100%;
  margin-top: 10px;
  font-size: 1.1rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: .05em;

  @media $small-handheld {
    // width: 210px;
    flex-basis: 100%;
  }
}

@media $small-handheld {
  .order-size-form {
    .form-placeholder {
      font-size: 1.2rem;
    }
  }
}

.product-card--params-table {
  font-size: 1.6rem;
  margin-bottom: 30px;

  @media $small-handheld {
    font-size: 1.4rem;
  }

  th, td {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  th {
    text-align: left;
    font-weight: $semi-bold;
    white-space: nowrap;
  }

  td {
    padding-left: 40px;
    font-weight: normal;
    width: 100%;
  }
}

.product-card--features {
  display: flex;
  margin-top: 50px;
  margin-bottom: 30px;

  .feature {
    text-align: center;
  }

  .feature + .feature {
    margin-left: 60px;
  }

  .feature--icon {
    lineh-height: 80px;
    margin-bottom: 10px;

    img {
      vertical-align: middle;
    }
  }

  .feature--caption {
    font-size: 1.4rem;
  }
}


// Features section

.product-card-shop-features {
  background: $almost-white;

  .wrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 115px;
    padding-bottom: 100px;

    @media $small-handheld {
      flex-wrap: wrap;
    }

    @media $mobile {
      padding-top: 40px;
      padding-bottom: 30px;
    }
  }

  .feature {
    @media $small-handheld {
      flex-basis: calc(50% - 10px);

      &:nth-child(2) ~ .feature {
        margin-top: 60px;
      }
    }

    @media $small-mobile {
      flex-basis: 100%;

      &:first-child ~ .feature {
        margin-top: 40px;
      }
    }
  }

  .feature + .feature {
    margin-left: 20px;

    @media $small-handheld {
      margin-left: 0;
    }
  }

  .feature--caption-line {
    position: relative;
    margin-bottom: 20px;
  }

  .feature--caption-line,
  .feature--text,
  .feature--link {
    padding-left: 70px;
  }

  .feature--number {
    position: absolute;
    left: 0; bottom: -5px;
    font-size: 4.8rem;
    font-weight: bold;

    @media $mobile {
      font-size: 3.5rem;
      bottom: -3px;
    }
  }

  .feature--caption {
    font-size: 2.4rem;
    font-weight: bold;
    font-variant: small-caps;
    text-transform: lowercase;

    @media $mobile {
      @media $not-small-mobile {
        font-size: 2rem;
      }
    }

    @media $small-mobile {
      font-size: 2.3rem;
    }
  }

  .feature--text {
    font-size: 1.6rem;
    line-height: 1.75;
    color: $dark;
    min-height: 56px;
    margin-bottom: 40px;


    @media $small-handheld {
      margin-bottom: 20px;
    }

    @media $mobile {
      font-size: 1.4rem;
    }

    a {
      text-decoration: none;
      display: inline-block;
      line-height: 1;
      border-bottom: 1px solid rgba($dark, .5);
    }
  }

  .feature--link .a,
  .feature--link a {
    display: inline-block;
    font-size: 1.6rem;
    font-weight: bold;
    padding-right: 40px;
    text-decoration: none;
    position: relative;

    @media $mobile {
      font-size: 1.4rem;
    }

    &:after {
      content: '';
      display: block;
      width: 20px; height: 20px;
      border-top: 2px solid $black;
      border-right: 2px solid $black;
      position: absolute;
      right: 0; top: 50%;
      transform: rotate(45deg) translateX(-50%);
      margin-top: -2px;
    }
  }
}


.product-card--thumbs {
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;

  @media $small-handheld {
    padding-left: 30px;
    padding-right: 30px;
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0;
    font-size: 0;
    width: 30px; height: calc(100% - 8px);
    z-index: 5;
    background: $white;
    opacity: 1;

    &:before {
      content: '';
      display: block;
      width: 8px; height: @width;
      border-top: 3px solid $gray;
      position: absolute;
      top: 50%;
      transition: all .25s ease;
      margin-top: -2px;
    }
  }

  .slick-prev {
    left: 0;

    &:before {
      left: 50%;
      border-left: 3px solid $gray;
      transform: rotate(-45deg) translate(-50%, -50%);
      transform-origin: left top;
      margin-left: 4px;
    }
  }

  .slick-next {
    right: 0;

    &:before {
      right: 50%;
      border-right: 3px solid $gray;
      transform: rotate(45deg) translate(0%, -100%);
      transform-origin: left top;
      margin-right: 2px;
    }
  }

  .slick-list {
    margin-left: -10px;
  }

  .item {
    position: relative;
    margin-top: 0 !important;
    text-align: center;
    outline: none;
    cursor: pointer;
    transition: all .25s ease;

    &:before {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    img {
      position: absolute;
      right: 0; top: 50%;
      transform: translateY(-50%);
      width: calc(100% - 10px); height: calc(100% - 10px);
      object-fit: cover;
    }
  }

  .slick-current {
    opacity: .5;
  }

  .slick-track {
    min-width: 100%;
  }
}


// Others section

.product-card-others {
  padding-top: 95px;

  @media $mobile {
    padding-top: 50px;
  }
}

.product-card-others--caption {
  @extend .caption;
  text-align: center;
  margin-bottom: 70px;

  @media $mobile {
    margin-bottom: 40px;
  }

  &:after {
    left: 50%;
    transform: translateX(-50%);
  }
}

.moving-to-basket {
  transition:all .75s ease;
  z-index: 10000;
}



// Spoilers

.text-section {
  border-top: 1px solid #c4c4c4;
  margin: 0 40px;

  @media only screen and (max-width: 480px) {
    margin: 0 15px;
  }
}

.text-section:first-child {
  margin-top: 15px;
}

.text-section:last-child {
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 35px;
}

.text-section__caption {
  font-size: 2.2rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: .01em;
  padding: 15px 0 17px;
  cursor: pointer;

  @media $small-mobile {
    font-size: 1.4rem;
    padding-top: 10px;
    padding-bottom: 12px;
  }

  .wrapper {
    position: relative;
  }

  .icon {
    display: block;
    position: absolute;
    right: 100%; top: 50%;
    transform: translateY(-50%);
    width: 40px; height: 40px;
    margin-top: 1px;
    transition: all .25s ease;

    @media $small-mobile {
      margin-top: -1px;
    }

    &:before, &:after {
      content: '';
      display: block;
      background: $black;
      position: absolute;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
    }

    &:before {
      width: 17px; height: 3px;

      @media $small-mobile {
        width: 12px; height: 2px;
      }
    }

    &:after {
      width: 3px; height: 17px;

      @media $small-mobile {
        width: 2px; height: 12px;
      }
    }
  }
}

.text-section__body {
  font-size: 1.6rem;
  line-height: 1.35;
  overflow: hidden;
  max-height: 0;
  transition: all .25s ease;

  @media $small-mobile {
    font-size: 1.4rem;
  }
}

.text-section.is-expanded {
  .text-section__caption .icon {
    transform: translateY(-50%) rotate(-45deg);
  }

  .text-section__body {
    max-height: var(--max-height);
    padding-bottom: 50px;
  }
}
