
/*   -=========== Collections ===========-   */

.collections--caption {
  margin-bottom: 20px;
}

$collection-button-height = 49px;

.collections-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }

  .item {
    padding-bottom: $collection-button-height;
    position: relative;

    @media $not-mobile {
      flex-basis: calc(50% - 1vw);
      margin-bottom: 2.5vw;
    }

    @media $mobile {
      flex-basis: 100%;
      margin-bottom: 50px;
    }

    @media $small-handheld {
      padding-bottom: 120px;
    }
  }

  .item--image {
    display: block;
    position: relative;
    margin-bottom: 10px;

    &:before {
      content: '';
      display: block;
      padding-bottom: 75%;
    }

    img {
      position: absolute;
      left: 0; top: 0;
      width: 100%; height: 100%;
      object-fit: cover;
    }
  }

  .item--name {
    text-align: center;
    margin-bottom: 20px;

    a {
      @extend .h3;
      text-transform: uppercase;
      text-decoration: none;
    }
  }

  .item--text {
    text-align: center;
    margin-bottom: 20px;

    @media $mobile {
      font-size: 1.4rem;
      line-height: 1.5;
    }
  }

  .item--link {
    text-align: center;
    width: 100%;
    position: absolute;
    left: 0; bottom: 0;

    .btn {
      @extend .black-btn;
      width: 210px;
      line-height: $collection-button-height;
      padding: 0 10px;
      text-transform: uppercase;
      font-size: 1.1rem;
      font-weight: bold;
      letter-spacing: .05em;

      @media $small-mobile {
        font-size: 1.2rem;
      }
    }

    .btn-callback {
      background: #f39406;
      margin-left: 5px;
      box-shadow: none;

      &:hover {
        box-shadow: inset 0 0 0 1px #000;
      }

      @media $small-handheld {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }
}

.portfolio-callback-btn {
  @extend .btn, .black-btn;
  background: #f39406;
  line-height: 50px;
  padding: 0 30px;
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: bold;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 30px !important;
}

.collections-list_portfolio {
  .item--text {
    text-align: left;

    p + p {
      margin-top: .5em;
    }
  }
}

.alphabet-filter {
  margin-bottom: 30px;

  .item {
    display: inline-block;
    font-weight: bold;
    text-transform: uppercase;
  }

  .item:not(.is-active) {
    cursor: pointer;
  }

  .item.is-active {
    text-decoration: underline;
  }

  .item + .item {
    margin-left: 5px;
  }

  .item_all {
    display: none;
  }

  .item.is-active ~ .item_all {
    display: inline-block;
  }
}

.collection-page--header {
  background-position: 50% 0;
  background-size: cover;
  overflow: hidden;
  position: relative;

  @media $not-mobile {
    height: 350px;

    .wrapper {
      position: absolute;
      left: 50%; top: 0;
      height: 100%;
      transform: translateX(-50%);
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.collection-page--header-content {
  background: rgba($white, .9);
  padding: 10px 30px 20px;
  text-align: center;
  box-sizing: border-box;

  @media $not-mobile {
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -50%);
  }

  @media $mobile {
    margin-top: 20px;
    margin-bottom: 20px;

    .h1 {
      line-height: 1.2;
      margin-bottom: 10px;
    }
  }

  @media $portrait-tablet {
    width: 90%;
  }
}

@media $mobile {
  .collection-page--header-text {
    font-size: 1.4rem;
  }
}

.collection-page--header + .container-with-aside {
  .main-section {
    padding-top: 30px;
  }

  .catalogue--sorting {
    margin-bottom: 30px;
  }
}
